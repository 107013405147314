import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/core/auth/auth.service';
import {
  AmplifyApiService,
  AmplifyRequestInput,
} from 'src/app/core/services/amplify-api.service';

@Injectable({
  providedIn: 'root',
})
export class FileItemService {
  private templateDownloadUrl: string;
  private defaultHeaders: any;

  constructor(
    private authService: AuthService,
    private amplifyApiService: AmplifyApiService
  ) {
    this.templateDownloadUrl = environment.bulkTemplate.downloadUrl;

    if (!!this.authService.user) {
      this.defaultHeaders = {
        'Content-Type': 'application/json',
        'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
      };
    }

    this.authService.user$.subscribe((user) => {
      if (user)
        this.defaultHeaders = {
          'Content-Type': 'application/json',
          'x-ontrax-identity': `${user.username};${user.currentRoleAcronym}`,
        };
    });
  }

  downloadFileService(fileName: string, originalName: string) {
    this.amplifyApiService
      .request('get', {
        apiName: 'PortalAPI',
        path: `/file-download?file=${fileName}`,
        options: {
          headers: this.defaultHeaders,
        },
      } as unknown as AmplifyRequestInput<'get'>)
      .then((data) => {
        saveAs(data, originalName);
      });
  }

  downloadBulkTemplate() {
    const url = this.templateDownloadUrl;
    window.open(url);
  }

  async getFileUrl(fileName: string) {
    return this.amplifyApiService.request('get', {
      apiName: 'PortalAPI',
      path: `/file-download?file=${fileName}`,
      options: {
        headers: this.defaultHeaders,
      },
    } as unknown as AmplifyRequestInput<'get'>);
  }
}
