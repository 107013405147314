import { Injectable } from '@angular/core';

import { OfferStatus } from './exterior-wash-offer-response.model';
import {
  AmplifyApiService,
  AmplifyRequestInput,
} from '../core/services/amplify-api.service';

@Injectable({
  providedIn: 'root',
})
export class ExteriorWashOfferResponseService {
  private defaultHeaders: Record<string, string>;

  constructor(private amplifyApiService: AmplifyApiService) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };
  }

  async exteriorWashResponse(token: string, offerResponseStatus: OfferStatus) {
    return this.amplifyApiService.request('put', {
      apiName: 'ExteriorWashOfferAPI',
      path: '/email-response',
      options: {
        headers: this.defaultHeaders,
        body: {
          token,
          offerResponseStatus,
        },
      },
    } as unknown as AmplifyRequestInput<'put'>);
  }
}
