import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { MenuOption, Title } from '../menu/menu.model';
import { User } from '../../core/model/user.model';
import { WashRequest } from './wash-list.model';
import {
  Sort,
  ElasticSearch,
} from 'src/app/core/model/search-definition.model';

import { APIService } from 'src/app/core/API.service';
import { WashListService } from './wash-list.service';
import { AuthService } from '../../core/auth/auth.service';
import { RolePickerService } from 'src/app/core/services/role-picker.service';
import { BusinessPartnerService } from 'src/app/core/services/business-partner.service';
import { pageSize } from 'src/app/core/elasticsearch.service';

import { WashItemComponent } from './wash-item/wash-item.component';
import { WashCreateComponent } from './wash-create/wash-create.component';

@Component({
  selector: 'app-wash-list',
  templateUrl: './wash-list.component.html',
  styleUrls: ['./wash-list.component.scss'],
})
export class WashListComponent implements OnInit, OnDestroy {
  static route = 'wash-list';
  @ViewChild('listBody', { static: false }) listBody: ElementRef;
  @ViewChildren(WashItemComponent)
  washItemComponent: QueryList<WashItemComponent>;

  option: MenuOption;
  private washRequests$: Subscription;
  private user: User = this.authService.user;
  private conversionSubscription$: Subscription;
  private completionSubscription$: Subscription;

  private isPendingRefresh = false;
  private listEditingWashRequest = new Map<string, boolean>();

  listDetailsWashRequest = new Map<string, boolean>();
  hasLoadedBPs$: Observable<boolean>;
  washRequests: WashRequest[] = [];
  isVisible: boolean;
  isLoadingScreen: boolean;
  isLoadingList: boolean;
  hasQualaWorkerRole: boolean;
  title: string;
  filter: UntypedFormControl;
  onlyMine: UntypedFormControl;
  ascSort: boolean;
  sortBy: string;
  elasticSearch: ElasticSearch;
  isNotMobile: boolean;
  terminal: string;

  mobileDisclaimer = `The Estimated Completion Time (ECT) shown below
  for each request is subject to change if the wash request details are
  changed and/or the trailer does not arrive when expected. If you see a
  dash instead of a date, it means that the facility does not yet have the
  ECT feature activated.`;
  isReadMore = false;

  constructor(
    private api: APIService,
    private washListService: WashListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private rolePickerService: RolePickerService,
    private businessPartnerService: BusinessPartnerService
  ) {}

  private initSort() {
    if (this.authService.hasQualaWorkerRole()) {
      switch (this.option) {
        case 'schneider-portal-completions':
          this.ascSort = false;
          this.sortBy = 'completeTime';
          break;

        default:
          this.ascSort = true;
          this.sortBy = 'operatedBy.keyword';
          break;
      }
    } else {
      this.ascSort = null;
      this.sortBy = null;
    }
  }

  private trackGtmTagFilter(value) {
    const view = this.washRequests.length;
    const page = pageSize > view ? 0 : Math.ceil(view / pageSize) - 1;

    const sort = this.getSort()
      ? `&sort=${this.getSort().field.split('.')[0]}`
      : '';
    let gtmTag;

    if (value) {
      gtmTag = {
        event: 'pageview',
        dp: `${window.location.pathname}/?q=${value}${sort}&page=${page}&view=${view}`,
        dh: window.location.host,
      };
    }

    this.gtmService.pushTag(gtmTag);
  }

  ngOnInit() {
    this.listenForRoleChange();

    this.filter = new UntypedFormControl();
    this.filter.valueChanges.subscribe((value) => {
      this.washListService
        .loadByOption(
          this.option,
          new ElasticSearch(0, this.onlyMine.value, value, this.getSort())
        )
        .finally(() => {
          this.isLoadingList = false;
          this.trackGtmTagFilter(value);
        });
    });

    this.onlyMine = new UntypedFormControl(false);
    this.onlyMine.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.washListService
          .loadByOption(
            this.option,
            new ElasticSearch(0, value, this.filter.value, this.getSort())
          )
          .finally(() => {
            this.isLoadingList = false;
          });
      });

    this.route.params.subscribe((params) => {
      if (this.listBody) {
        this.listBody.nativeElement.scrollTo(0, 0);
      }
      this.isLoadingScreen = true;
      this.option = params.option;
      this.washListService.setOption(this.option);
      this.initSort();
      this.getSessionTitle(this.option);
      this.washListService
        .loadByOption(
          this.option,
          new ElasticSearch(
            0,
            this.onlyMine.value,
            this.filter.value,
            this.getSort()
          )
        )
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          this.isLoadingScreen = false;
        });
    });

    if (this.authService.hasQualaWorkerRole()) {
      this.conversionSubscription$ = this.api
        .OnOrderConversionStatusChangesListener(
          this.authService.user.currentTerminal.id
        )
        .subscribe((value) => {
          if (this.option === 'accepted') {
            if (
              Array.from(this.listEditingWashRequest.values()).every(
                (element) => !element
              )
            ) {
              this.isPendingRefresh = false;
              this.washListService
                .loadByOption(
                  this.option,
                  new ElasticSearch(0, value, this.filter.value, this.getSort())
                )
                .then(() =>
                  this.washListService.updateWashRequestsConversionStatuses(
                    value.data.onOrderConversionStatusChanges.requests
                  )
                )
                .finally(() => {
                  this.isLoadingList = false;
                });
            } else {
              this.isPendingRefresh = true;
            }
          }
        }) as Subscription;

      this.completionSubscription$ = this.api
        .OnSchneiderCompletionStatusChangesListener(
          this.authService.user.currentTerminal.id
        )
        .subscribe((value) => {
          if (this.option === 'schneider-portal-completions') {
            this.isPendingRefresh = false;
            this.washListService
              .loadByOption(
                this.option,
                new ElasticSearch(0, value, this.filter.value, this.getSort())
              )
              .then(() =>
                this.washListService.updateWashRequestsSchneiderCompletionStatuses(
                  value.data.onSchneiderCompletionStatusChanges.requests
                )
              )
              .finally(() => {
                this.isLoadingList = false;
              });
          }
        }) as Subscription;
    }

    this.washRequests$ = this.washListService.washRequests.subscribe(
      (items) => {
        this.washRequests = items;
      }
    );
    this.hasQualaWorkerRole = this.authService.hasQualaWorkerRole();
    this.initSort();
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isVisible = flag)
    );
    this.hasLoadedBPs$ = this.businessPartnerService.hasLoadedBPs;
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isNotMobile = flag)
    );
    this.terminal = this.hasQualaWorkerRole
      ? this.user.currentTerminal.key
      : '';
  }

  ngOnDestroy() {
    this.washRequests$.unsubscribe();
    if (this.hasQualaWorkerRole) {
      this.conversionSubscription$.unsubscribe();
      this.completionSubscription$.unsubscribe();
    }
  }

  createNewRequest() {
    this.washListService.openCreateForm(WashCreateComponent);
  }

  print() {
    this.washItemComponent
      .filter((prop) => prop.showDetail)
      .map((prop) => prop.toggleDetail());
    setTimeout(() => {
      window.print();
    }, 500);
  }

  private getSort() {
    if (typeof this.ascSort !== 'undefined' && this.ascSort !== null) {
      const sortOrder = this.ascSort ? 'asc' : 'desc';
      const sort = new Sort(this.sortBy, sortOrder);
      return sort;
    }
    return null;
  }

  get isDetailOpen() {
    return Array.from(this.listDetailsWashRequest.values()).some(
      (item) => item
    );
  }

  async onScroll() {
    this.isLoadingList = true;
    this.washListService
      .loadByOption(
        this.option,
        new ElasticSearch(
          this.washRequests.length,
          this.onlyMine.value,
          this.filter.value,
          this.getSort()
        )
      )
      .finally(() => {
        this.isLoadingList = false;
      });
  }

  shouldDisplayEctForTerminal(actualTerminal) {
    return this.washListService.shouldDisplayEctForTerminal(actualTerminal);
  }

  sortedBy(field) {
    if (field !== this.sortBy) {
      this.ascSort = true;
    } else {
      this.ascSort = !this.ascSort;
    }
    this.sortBy = field;

    this.washListService
      .loadByOption(
        this.option,
        new ElasticSearch(
          0,
          this.onlyMine.value,
          this.filter.value,
          this.getSort()
        )
      )
      .finally(() => {
        this.isLoadingList = false;
      });
  }

  isEditingChanged(event: { isEditing: boolean; washRequestId: string }) {
    this.listEditingWashRequest.set(event.washRequestId, event.isEditing);
    if (
      this.isPendingRefresh &&
      Array.from(this.listEditingWashRequest.values()).every((value) => !value)
    ) {
      this.washListService.loadByOption(
        this.option,
        new ElasticSearch(0, null, this.filter.value, this.getSort())
      );
    }
  }

  isOpenDetailsChanged(event: { isOpen: boolean; washRequestId: string }) {
    this.listDetailsWashRequest.set(event.washRequestId, event.isOpen);
  }

  getSessionTitle(param: string = MenuOption.Draft) {
    const title = {
      [MenuOption.Draft]: Title.Draft,
      [MenuOption.NewWashRequests]: Title.NewWashRequests,
      [MenuOption.Pending]: Title.Pending,
      [MenuOption.Accepted]: Title.Accepted,
      [MenuOption.NeedsAction]: Title.NeedsAction,
      [MenuOption.InProgress]: Title.InProgress,
      [MenuOption.Hold]: Title.Hold,
      [MenuOption.CreditHold]: Title.CreditHold,
      [MenuOption.Completed]: Title.Completed,
      [MenuOption.Canceled]: Title.Canceled,
      [MenuOption.Rejected]: Title.Rejected,
      [MenuOption.Submitted]: Title.Submitted,
      [MenuOption.Expired]: Title.Expired,
      [MenuOption.SchneiderPortalCompletions]: Title.SchneiderPortalCompletions,
    };
    this.title = title[param];
  }

  shouldDisplayCreateButton() {
    return !(
      this.user.currentTerminal &&
      this.user.currentTerminal.active === false &&
      this.authService.hasManagerRole()
    );
  }

  togglShowText() {
    this.isReadMore = !this.isReadMore;
  }

  private listenForRoleChange(): void {
    this.rolePickerService.roleChanged$.subscribe((roleChanged: boolean) => {
      if (roleChanged) {
        this.isLoadingList = true;
        this.isLoadingScreen = true;
        this.washRequests = [];
        this.washRequests$.unsubscribe();
        if (this.hasQualaWorkerRole) {
          this.conversionSubscription$.unsubscribe();
          this.completionSubscription$.unsubscribe();
        }
      }
    });
  }
}
