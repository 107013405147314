import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

import { environment } from 'src/environments/environment';

import { ConnectivityService } from '../services/connectivity.service';
import { AuthService } from '../auth/auth.service';

let locationHash: string;

async function handleStateRedirect() {
  locationHash ??= window.location.hash;
  const params = new URLSearchParams(locationHash.substring(1));
  const stateParam = params.get('state');

  const state = (function () {
    try {
      new URL(stateParam);
      return stateParam;
    } catch (_) {
      return '';
    }
  })();

  if (state) {
    window.location.href = state;

    // Return a Promise that never resolves to prevent the site from loading
    // while the user is being redirected.
    return new Promise(() => {});
  }

  return Promise.resolve();
}

async function handleUserAuthentication(
  keycloak: KeycloakService,
  authService: AuthService
) {
  const {
    idToken,
    token: accessToken,
    refreshToken,
  } = keycloak.getKeycloakInstance();

  if (idToken && accessToken && refreshToken) {
    await authService.setAmplifyV5PlusAuthCookies({
      accessToken,
      idToken,
      refreshToken,
    });

    await handleStateRedirect();
  }
}

export function initializeKeycloak(
  connectivityService: ConnectivityService,
  keycloak: KeycloakService,
  authService: AuthService
) {
  if (!connectivityService.isOnline) {
    return () => true;
  }

  locationHash = window.location.hash;

  return () =>
    keycloak
      .init({
        config: {
          url: environment.keycloak.issuer,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId,
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html',
        },
        loadUserProfileAtStartUp: false,
        enableBearerInterceptor: false,
      })
      .then(async () => {
        await handleUserAuthentication(keycloak, authService);

        keycloak.keycloakEvents$.subscribe({
          async next(event) {
            if (!connectivityService.isOnline) {
              return;
            }

            if (event.type == KeycloakEventType.OnTokenExpired) {
              await keycloak.updateToken(-1);
              await handleUserAuthentication(keycloak, authService);
            } else if (
              event.type == KeycloakEventType.OnAuthRefreshError ||
              event.type == KeycloakEventType.OnAuthLogout
            ) {
              window.location.assign('/');
            }
          },
        });
      });
}
