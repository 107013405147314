import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { uploadData } from '@aws-amplify/storage';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/core/localstorage.service';
import { UploadedFile } from '../file-upload/file-upload.model';
import { v1 } from 'uuid';

@Component({
  selector: 'app-file-upload-thumb',
  templateUrl: './file-upload-thumb.component.html',
  styleUrls: ['./file-upload-thumb.component.scss'],
})
export class FileUploadThumbComponent implements OnInit {
  @ViewChild('fileUploader', { static: false }) fileUploader: ElementRef;

  @Input() label;
  @Input() fileList: Array<UploadedFile>;
  @Input() maxFileSize: number;
  @Input() downloadOnly: boolean;
  @Input() customFileFlag: string;

  isUploading = false;
  errorMessage: string;
  filesQty: number;
  isUnauthenticated: boolean;

  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.countFiles();
  }

  get shouldShowUploadBtn(): boolean {
    return !this.filesQty;
  }

  get shouldDisplayFileList(): boolean {
    let display = false;
    if (this.fileList.length === 0) {
      return false;
    } else if (this.customFileFlag) {
      this.fileList.forEach((file) => {
        if (file.customFileFlag === this.customFileFlag) {
          display = true;
          return;
        }
      });
      return display;
    } else {
      this.fileList.forEach((file) => {
        if (!file.customFileFlag) {
          display = true;
          return;
        }
      });
      return display;
    }
  }

  private resetFileUploader() {
    this.fileUploader.nativeElement.value = null;
    this.isUploading = false;
  }

  async handleFileInput(event) {
    if (
      this.maxFileSize &&
      event.target.files.item(0).size > this.maxFileSize
    ) {
      this.errorMessage = 'File size exceeds allowed size.';
      return;
    }
    this.isUploading = true;
    this.errorMessage = '';

    const fileToUpload = event.target.files.item(0);
    const fileIdName = v1();

    const user = this.authService.user
      ? this.authService.user.username
      : 'unauthenticated';

    const file: UploadedFile = {
      originalName: fileToUpload.name,
      user,
      uploadTimestamp: Math.round(Date.now() / 1000),
      extension: fileToUpload.name.split('.')[1],
      type: fileToUpload.type,
      id: fileIdName,
    };

    if (this.customFileFlag) {
      file.customFileFlag = this.customFileFlag;
    }

    if (this.validContentType(fileToUpload.type)) {
      await uploadData({
        path: fileIdName,
        data: fileToUpload,
        options: {
          contentType: fileToUpload.type,
        },
      });

      if (user === 'unauthenticated') {
        this.isUnauthenticated = true;
        this.localStorage.emptyLocalStorage(); // clear identity pool id from local storage
      }

      this.fileList.push(file);
    } else {
      this.toastr.error('', 'Invalid file!');
    }

    this.resetFileUploader();
    this.countFiles();
  }

  validContentType(contentType: string): boolean {
    const types = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'mp4',
      'mov',
      'wmv',
      'pdf',
      'doc',
      'txt',
    ];
    const result = types.some((substring) => {
      return contentType.includes(substring);
    });
    return result;
  }

  deleteItem(index: number) {
    this.fileList.splice(index, 1);
    this.countFiles();
  }

  countFiles() {
    this.filesQty = 0;
    if (this.customFileFlag && this.fileList.length > 0) {
      this.fileList.forEach((file) => {
        if (file.customFileFlag === this.customFileFlag) {
          this.filesQty = ++this.filesQty;
        }
      });
    } else if (!this.customFileFlag && this.fileList.length > 0) {
      this.fileList.forEach((file) => {
        if (!file.customFileFlag) {
          this.filesQty = ++this.filesQty;
        }
      });
    }
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({ block: 'center' });
  }
}
