import { Injectable, NgZone } from '@angular/core';
import { ElasticsearchService } from 'src/app/core/elasticsearch.service';
import { WashRequest } from '../wash-list/wash-list.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Dispatcher } from '../notification-preferences/notification-preferences.model';
import { BehaviorSubject, from } from 'rxjs';
import {
  DataStore,
  RequestGroup,
  Request,
  ExteriorWash,
} from './exterior-wash-offer.model';
import {
  ElasticSearch,
  Filter,
  Query,
  SearchDefinition,
  Sort,
} from 'src/app/core/model/search-definition.model';
import {
  AmplifyApiService,
  AmplifyRequestInput,
} from 'src/app/core/services/amplify-api.service';

@Injectable({
  providedIn: 'root',
})
export class ExteriorWashOfferService {
  private defaultHeaders: any;
  private elasticSearch: ElasticSearch;

  public dataStore: DataStore = {
    dispatchers: [],
    requests: [],
  };

  private _requestGroup = new BehaviorSubject<RequestGroup[]>([]);
  readonly requestGroup = this._requestGroup.asObservable();

  private _dispatchers = new BehaviorSubject<Dispatcher[]>([]);
  readonly dispatchers = this._dispatchers.asObservable();

  constructor(
    private authService: AuthService,
    private amplifyApiService: AmplifyApiService,
    private elasticsearchService: ElasticsearchService,
    private zone: NgZone
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };

    this.authService.user$.subscribe((user) => {
      if (user)
        this.defaultHeaders = {
          'Content-Type': 'application/json',
          'x-ontrax-identity': `${user.username};${user.currentRoleAcronym}`,
        };
    });
  }

  async loadDispatchersByBusinessPartnerId(businessPartnerId: string) {
    try {
      await this.getDispatchers(businessPartnerId);
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        throw error.errors[0];
      }
      throw error;
    }
  }

  async loadWashRequests(elasticSearchParams?: ElasticSearch) {
    try {
      this.elasticSearch = elasticSearchParams
        ? elasticSearchParams
        : this.elasticSearch;
      // If there is no specific search, start it from 0
      this.elasticSearch.from = elasticSearchParams
        ? elasticSearchParams.from
        : 0;

      const filter = new Filter([], this.elasticSearch.filterValue);

      const terminal = this.authService.user.currentTerminal.key;

      await this.getWashRequestList(
        terminal,
        this.elasticSearch.from,
        filter,
        this.elasticSearch.sort
      );

      return 0;
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        throw error.errors[0];
      }
      throw error;
    }
  }

  private async getWashRequestList(
    terminal: string,
    // tslint:disable-next-line: no-shadowed-variable
    from: number = 0,
    filter: Filter,
    sort?: Sort
  ) {
    const queries = [];

    queries.push(
      new Query([
        new SearchDefinition('status', ['ACCEPTED', 'SCHEDULED'], 'OR'),
        new SearchDefinition('terminal', [terminal]),
      ])
    );

    queries.push(
      new Query([
        new SearchDefinition('status', ['STARTED', 'PAUSED'], 'OR'),
        new SearchDefinition('terminal', [terminal]),
      ])
    );

    await this.zone.run(async () => {
      const data = await this.elasticsearchService.searchWashRequest(
        queries,
        from,
        filter,
        sort
      );
      if (from === 0) {
        this.dataStore.requests = [];
      }

      this.dataStore.requests = data.list.map((item) => {
        let date = 'It will arrive on ';
        if (item.arrivalTime) {
          date += new Date(item.arrivalTime * 1000).toDateString();
        } else {
          date = 'Others';
        }

        const washRequest = new WashRequest(item);

        return {
          washRequest,
          label: this.labelToDisplayWashRequest(washRequest),
          date,
        };
      });

      this._requestGroup.next(
        this.createRequestGroup([...this.dataStore.requests])
      );
    });
  }

  private async getDispatchers(businessPartnerId: string): Promise<void> {
    await this.zone.run(async () => {
      const data = await this.amplifyApiService.request('get', {
        apiName: 'OnTraxAPI',
        path: '/get-dispatchers',
        options: {
          headers: this.defaultHeaders,
          queryParams: {
            businessPartnerId,
          },
        },
      } as unknown as AmplifyRequestInput<'get'>);

      this.dataStore.dispatchers = data.map((item) => new Dispatcher(item));
      this._dispatchers.next([...this.dataStore.dispatchers]);
    });
  }

  private labelToDisplayWashRequest(item: WashRequest): string {
    const toDisplay = [];
    if (item.customer) {
      toDisplay.push(item.customer);
    }
    if (item.containerNumber) {
      toDisplay.push(item.containerNumber);
    }
    if (item.containerType) {
      toDisplay.push(item.containerType);
    }

    if (!toDisplay.length) {
      return 'Not identified';
    }

    return toDisplay.join(' | ');
  }

  private createRequestGroup(requests: Request[]): RequestGroup[] {
    const requestGroup: RequestGroup[] = [];
    const keys = [];

    requests.forEach((request) => {
      if (!keys.includes(request.date)) {
        const value = {
          date: request.date,
          requests: [request],
        };

        requestGroup.push(value);
        keys.push(request.date);
      } else {
        const value = requestGroup.find((item) => item.date === request.date);
        value.requests.push(request);
      }
    });

    return requestGroup;
  }

  submit(exteriorWash: ExteriorWash) {
    return this.amplifyApiService.request('put', {
      apiName: 'ExteriorWashOfferAPI',
      path: '/create',
      options: {
        headers: this.defaultHeaders,
        body: { ...exteriorWash },
      },
    } as unknown as AmplifyRequestInput<'put'>);
  }
}
