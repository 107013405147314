import { Amplify, ResourcesConfig } from 'aws-amplify';
import { Client, generateClient } from 'aws-amplify/api';

import { environment } from 'src/environments/environment';

export class PublicAPIService {
  public client: Client;

  constructor() {
    this.configureAmplify();
    this.client = generateClient();
  }

  private configureAmplify() {
    const amplifyConfig: ResourcesConfig = {
      API: {
        GraphQL: {
          endpoint: environment.publicAppSyncAPI,
          region: environment.region,
          defaultAuthMode: 'iam',
        },
      },
    };

    Amplify.configure(amplifyConfig);
  }

  async ListNcrIssueType(nextToken?: string) {
    const statement = `
      query ListNcrIssueType($nextToken: String) {
        listNcrIssueType(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
            rawName
            possibleContaminationOrDamage
          }
          nextToken
        }
      }
    `;

    const gqlAPIServiceArguments: any = {};

    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments,
    })) as any;

    return response.data.listNcrIssueType;
  }

  async ListNcrShiftStartTimes(nextToken?: string) {
    const statement = `
      query ListNcrShiftStartTimes($nextToken: String) {
        listNcrShiftStartTimes(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            displayName
          }
          nextToken
        }
      }
    `;

    const gqlAPIServiceArguments: any = {};

    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments,
    })) as any;

    return response.data.listNcrShiftStartTimes;
  }

  async ListNcrSignedStatement(nextToken?: string) {
    const statement = `
      query ListNcrSignedStatement($nextToken: String) {
        listNcrSignedStatement(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
          }
          nextToken
        }
      }
    `;
    const gqlAPIServiceArguments: any = {};

    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments,
    })) as any;

    return response.data.listNcrSignedStatement;
  }

  async ListNcrWhoHaveBeenNotified(nextToken?: string) {
    const statement = `
      query ListNcrWhoHaveBeenNotified($nextToken: String) {
        listNcrWhoHaveBeenNotified(nextToken: $nextToken) {
          __typename
          items {
            __typename
            order
            code
            displayName
          }
          nextToken
        }
      }
    `;

    const gqlAPIServiceArguments: any = {};

    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments,
    })) as any;

    return response.data.listNcrWhoHaveBeenNotified;
  }

  async GetAllWarehouses(nextToken?: string) {
    const statement = `
      query GetAllWarehousesPublic($nextToken: String) {
        getAllWarehousesPublic(nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            active
            searchKey
            terminalName
            terminalNameDisplay
            terminalNumber
            terminalNumberDisplay
            terminalLaunch
            operationalRegion
            linesOfBusiness
            country
            continent
          }
          nextToken
        }
      }
    `;

    const gqlAPIServiceArguments: any = {};

    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments,
    })) as any;

    return response.data.getAllWarehouses;
  }
}
