import { Injectable, NgZone } from '@angular/core';
import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { BehaviorSubject, ReplaySubject, from } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { uniqBy, get } from 'lodash';
import { utc } from 'moment';

import {
  WashRequest,
  ContainerType,
  ServiceType,
  WashBillToTerminal,
  TankType,
  ProductContent,
  Tank,
  CountMap,
  UOM,
  ReasonOfChangeOption,
  CustomerProductType,
  businessPartnerWithExteriorWashByDefault,
} from './wash-list.model';
import {
  APIService,
  SchneiderCompletionStatus,
} from 'src/app/core/API.service';
import { ElasticsearchService } from 'src/app/core/elasticsearch.service';
import { MenuOption } from '../menu/menu.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Heel } from './wash-heel/wash-heel.model';
import {
  SearchDefinition,
  Query,
  Filter,
  Sort,
  QueryType,
  ElasticSearch,
  TankElasticSearch,
  SearchRange,
  QueryName,
  SearchShouldDefinition,
} from 'src/app/core/model/search-definition.model';
import { OrderConversionStatuses } from './wash-list.model';
import { ManualStartStopData, Operator } from '../schedule/schedule.model';
import { OperatorsService } from 'src/app/shared/operators/operators.service';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { environment } from 'src/environments/environment';
import {
  AmplifyApiService,
  AmplifyRequestInput,
} from 'src/app/core/services/amplify-api.service';

const VOLUME_UOM_DATA: UOM[] = [
  {
    id: '85C3F9AF09CF49B698F55B9BC8F391A4',
    name: 'Liter',
    uOMType: 'V',
  },
  {
    id: '65B6F4B01D744A11AFC4B81BF7D03CF2',
    name: 'Yard',
    uOMType: 'V',
  },
  {
    id: 'CFB02D28D44D4E649726CF54F4F57685',
    name: 'Gallon',
    uOMType: 'V',
  },
  {
    id: '447743E8C5E04356940AAC6570781C5E',
    name: 'Cubic Meters',
    uOMType: 'V',
  },
];

const YESTERDAY_TIMESTAMP = utc().startOf('day').add(-1, 'day').unix();
const THREE_MONTHS_AGO_TIME_STAMP = 7889400000;
const SCHNDEIDER_COMPLETION_START_DATE = 1723557825;
const FIFTEEN_MINUTES_AGO_TIME_STAMP = 900000;
const SALES_ORDER_BTN_LABEL = 'Open Sales Order in Etendo';
const WORK_ORDER_BTN_LABEL = 'Open Work Order in Etendo';
const SALES_ORDER_TAB_ID = '186';
const WORK_ORDER_TAB_ID = 'AB955F8DD46E4F308199E14B13AECAE5';

interface DataStore {
  operators: Operator[];
  washRequests: WashRequest[];
  containerTypes: ContainerType[];
  serviceTypes: ServiceType[];
  billToTerminals: WashBillToTerminal[];
  tanks: TankType[];
  productContents: ProductContent[];
  uOMVolume: UOM[];
  reasonOfChangeOptions: ReasonOfChangeOption[];
  exteriorWashProducts: CustomerProductType[];
  recentOperators: Operator[];
}

interface ResponseBody {
  data: {
    washRequest: WashRequest;
    message: string;
    status: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WashListService {
  private defaultHeaders: any;
  private elasticSearch: ElasticSearch;
  private option: MenuOption;
  private terminalsWithEctEnabled = [
    '286-Joliet',
    '294-SouthGate',
    '279-Shreveport',
    '275-Houston',
    '206-Rockdale',
    '112-Louisville',
    '105-Coraopolis',
    '114-Jacksonville',
    '107-GraniteCity',
    '246-Birmingham',
    '255-Beaumont',
    '251-Delaware',
    '269-Clute',
    '249-Neenah',
    '276-Barberton',
    '101-Conley',
    '273-Geismar',
    '109-Chicago',
  ];
  public dataStore: DataStore = {
    operators: [],
    recentOperators: [],
    washRequests: [],
    containerTypes: [],
    serviceTypes: [],
    billToTerminals: [],
    tanks: [],
    productContents: [],
    uOMVolume: VOLUME_UOM_DATA,
    reasonOfChangeOptions: [],
    exteriorWashProducts: [],
  };

  private _washRequests = new BehaviorSubject<WashRequest[]>([]);
  readonly washRequests = this._washRequests.asObservable();

  private _containerTypes = new BehaviorSubject<ContainerType[]>([]);
  readonly containerTypes = this._containerTypes.asObservable();

  private _serviceTypes = new BehaviorSubject<ServiceType[]>([]);
  readonly serviceTypes = this._serviceTypes.asObservable();

  private _washBillToTerminals = new BehaviorSubject<WashBillToTerminal[]>([]);
  readonly washBillToTerminals = this._washBillToTerminals.asObservable();

  private _tanks = new BehaviorSubject<TankType[]>([]);
  readonly tanks = this._tanks.asObservable();

  private _isLoadingTanks = new BehaviorSubject<boolean>(false);
  readonly isLoadingTanks = this._isLoadingTanks.asObservable();

  private _productContents = new BehaviorSubject<ProductContent[]>([]);
  readonly productContents = this._productContents.asObservable();

  private _hasLoadedPCs = new BehaviorSubject<boolean>(false);
  readonly hasLoadedPCs = this._hasLoadedPCs.asObservable();

  private _uom = new BehaviorSubject<UOM[]>(this.dataStore.uOMVolume);
  readonly uoms = this._uom.asObservable();

  private _breakpointFlag = new BehaviorSubject(true);
  currentBreakpoint = this._breakpointFlag.asObservable();

  private _reasonOfChangeOptions = new BehaviorSubject<ReasonOfChangeOption[]>(
    []
  );
  readonly reasonOfChangeOptions = this._reasonOfChangeOptions.asObservable();

  private _exteriorWashProductsOptions = new BehaviorSubject<
    CustomerProductType[]
  >([]);
  readonly exteriorWashProductsOptions =
    this._exteriorWashProductsOptions.asObservable();

  // TODO: Implement a more elegant way to do this
  private filteredFields = [
    'tankOwner',
    'tankType',
    'lastContainedProductComp1Name',
    'washBillToTerminal',
    'operatedBy',
    'terminal',
    'tankNumber',
    'poNumber',
    'serviceType',
  ];

  private _countMap = new BehaviorSubject<CountMap>({ total: {}, new: {} });
  readonly countMap = this._countMap.asObservable();
  _operators = new ReplaySubject<Operator[]>();

  createWashOverlayDialogRef: OverlayRef;

  constructor(
    private authService: AuthService,
    private warehouseService: WarehouseService,
    private api: APIService,
    private overlay: Overlay,
    private elasticsearchService: ElasticsearchService,
    private operatorsService: OperatorsService,
    private zone: NgZone,
    private amplifyApiService: AmplifyApiService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.defaultHeaders['x-ontrax-identity'] =
          `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`;
        this.dataStore.operators = [];
        this.dataStore.recentOperators = [];
        this.loadOperators();
      }
    });

    this.option = MenuOption.Draft;
    this.loadOperators();
  }

  loadOperators() {
    if (this.authService?.user?.currentTerminal?.number) {
      this.operatorsService
        .loadOperators(this.authService.user.currentTerminal.number)
        .then((operators) => {
          this.dataStore.operators = operators;
          this._operators.next(operators);
          this.loadRecentOperators(operators);
        });
    }
    this.updateRecentOperators();
  }

  async updateRecentOperators() {
    this.api
      .OnRecentOperatorsChangesListener(
        this.authService.user.currentTerminal.id
      )
      .subscribe((res) => {
        const recentOperatorIds = get(
          res,
          ['value', 'data', 'onRecentOperatorsChanges', 'users'],
          []
        );
        this.mergeAndStoreRecentOperators(
          this.dataStore.operators,
          recentOperatorIds
        );
      });
  }

  loadRecentOperators(operators: Operator[]) {
    this.operatorsService
      .loadRecentOperators(this.authService.user.currentTerminal.id)
      .then((recentOperatorIds) => {
        this.mergeAndStoreRecentOperators(operators, recentOperatorIds);
      });
  }

  mergeAndStoreRecentOperators(
    operators: Operator[],
    recentOperatorsIds: string[]
  ) {
    const recentOperators: Operator[] = [];
    recentOperatorsIds.forEach((id) => {
      const operatorObject = operators.find(
        (operator) => operator.userId === id
      );
      if (operatorObject) {
        recentOperators.push(operatorObject);
      }
    });
    this.dataStore.recentOperators = recentOperators;
  }

  async loadWashBillToTerminal(businessPartnerId?: string, nextToken?: string) {
    const data = await this.api.GetBillToTerminal(businessPartnerId, nextToken);

    if (!data || !data.items) {
      return;
    }

    const processedData = data.items.map((item) => ({
      id: item.id,
      name: item.name,
      businessPartnerId: item.businessPartnerId,
      locationAddressId: item.locationAddressId,
      locationAddressName: item.locationAddressName,
    }));

    const uniqueData = uniqBy(
      [...this.dataStore.billToTerminals, ...processedData],
      'id'
    );

    this.dataStore.billToTerminals = uniqueData;
    this._washBillToTerminals.next(this.dataStore.billToTerminals);

    if (data.nextToken) {
      await this.loadWashBillToTerminal(businessPartnerId, data.nextToken);
    }
  }

  async getTank(containerOwnerId: string, containerTypeId: string) {
    this.dataStore.tanks = [];
    this._tanks.next(Object.assign({}, this.dataStore).tanks);
    this._isLoadingTanks.next(true);
    this.getTankPaginated(containerOwnerId, containerTypeId);
  }

  resetTankDataStore() {
    this.dataStore.tanks = [];
    this._tanks.next(Object.assign({}, this.dataStore).tanks);
  }

  async getTankPaginated(
    containerOwnerId: string,
    containerTypeId: string,
    nextToken?: string
  ) {
    const data = await this.api.GetTank(
      containerOwnerId,
      containerTypeId,
      nextToken
    );
    if (!data) {
      return;
    }

    this.dataStore.tanks = (this.dataStore.tanks || []).concat(
      (data.items || []).map((item) => ({
        id: item.id,
        name: item.searchKey,
        compartments: Number(item.compartments || '1'),
      }))
    );

    if (data.nextToken) {
      await this.getTankPaginated(
        containerOwnerId,
        containerTypeId,
        data.nextToken
      );
    } else {
      this.dataStore.tanks.sort((itemA, itemB) => {
        const itemAName = itemA.name.toLowerCase();
        const itemBName = itemB.name.toLowerCase();
        if (itemAName < itemBName) {
          return -1;
        }
        if (itemAName > itemBName) {
          return 1;
        }
        return 0;
      });
      this._tanks.next(Object.assign({}, this.dataStore).tanks);
      this._isLoadingTanks.next(false);
    }
  }

  async loadServiceType() {
    if (this.dataStore.serviceTypes && this.dataStore.serviceTypes.length > 0) {
      this._serviceTypes.next(Object.assign({}, this.dataStore).serviceTypes);
      return;
    }
    const data = await this.api.ListTypeOfService();
    this.dataStore.serviceTypes = data.map((item) => ({
      id: item.id,
      name: item.name,
      displayName: item.displayName,
      obId: item.obId,
      key: item.key,
      enabled: true,
    }));
    this._serviceTypes.next(Object.assign({}, this.dataStore).serviceTypes);
  }

  /**
   * Enable or disable options listed in serviceTypes based on a keys list.
   * @param enable Value to apply to the enabled property.
   * @param keys The list of keys to be changed, in case no value is passed every item in the list will be considered.
   */
  async toggleServiceTypeOptions(enable: boolean, keys?: string[]) {
    for (const serviceType of this.dataStore.serviceTypes) {
      serviceType.enabled =
        !keys || keys.includes(serviceType.key) ? enable : serviceType.enabled;
    }

    this._serviceTypes.next(Object.assign({}, this.dataStore).serviceTypes);
  }

  async loadContainerType() {
    if (
      this.dataStore.containerTypes &&
      this.dataStore.containerTypes.length > 0
    ) {
      this._containerTypes.next(
        Object.assign({}, this.dataStore).containerTypes
      );
      return;
    }
    const data = await this.api.ListTypeOfTank();
    this.dataStore.containerTypes = data
      .map((item) => ({
        id: item.id,
        name: item.name,
        canBeFoodGrade: !!item.aecdoFoodgradectrtypeName,
      }))
      // Sort by name
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    this._containerTypes.next(Object.assign({}, this.dataStore).containerTypes);
  }

  setOption(option: MenuOption) {
    this.option = option || MenuOption.Draft;
  }

  getOption() {
    const option = this.option;
    return option;
  }

  async loadByOption(
    option: MenuOption = MenuOption.Draft,
    elasticSearchParams?: ElasticSearch
  ) {
    try {
      this.elasticSearch = elasticSearchParams
        ? elasticSearchParams
        : this.elasticSearch;
      // If there is no specific search, start it from 0
      this.elasticSearch.from = elasticSearchParams
        ? elasticSearchParams.from
        : 0;
      const filter = new Filter(
        this.filteredFields,
        this.elasticSearch.filterValue,
        undefined,
        QueryName.simpleQueryString,
        true
      );
      if (this.authService.hasDispatcherRole()) {
        const onlyMineFilters = this.elasticSearch.onlyMine
          ? [
              new Filter(
                ['createdBy'],
                this.authService.user.username,
                QueryType.phrase
              ),
              new Filter(
                ['acceptedById'],
                this.authService.user.id,
                QueryType.phrase
              ),
            ]
          : null;
        const businessPartner =
          this.authService.user.businessPartnerName &&
          this.authService.user.businessPartnerName.replace(/\//g, '\\/');
        await this.getWashRequestListByOptionAsDispatcher(
          option,
          businessPartner,
          this.elasticSearch.from,
          filter,
          this.elasticSearch.sort,
          onlyMineFilters
        );
      } else {
        const terminal = this.authService.user.currentTerminal.key;

        await this.getWashRequestListByOption(
          option,
          terminal,
          this.elasticSearch.from,
          filter,
          this.elasticSearch.sort
        );
      }
      return 0;
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        throw error.errors[0];
      }
      throw error;
    }
  }

  async searchTanks(elasticSearchParams: TankElasticSearch) {
    try {
      return await this.elasticsearchService.seachTank(elasticSearchParams);
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        throw error.errors[0];
      }
      throw error;
    }
  }

  formatProductList(products: string[]): string {
    if (products.length === 0) return '';
    if (products.length === 1) return `${products[0]}.`;

    return (
      products.slice(0, -1).join(', ') +
      ' and ' +
      products[products.length - 1] +
      '.'
    );
  }

  uniqueNonNullProducts(allContentIds: string[]) {
    const uniqueNonNullValues = Array.from(
      new Set(allContentIds.filter((contentId) => contentId))
    ).map((contentId) => ({ contentId }));

    return uniqueNonNullValues;
  }

  updateWashRequestInDataStore(washRequest: WashRequest) {
    const index = this.dataStore.washRequests.findIndex(
      (item) => item.id === washRequest.id
    );
    if (index >= 0) {
      this.dataStore.washRequests.splice(index, 1, washRequest);
      this._washRequests.next(Object.assign({}, this.dataStore).washRequests);
    }
  }

  async loadProductContent(nextToken?: string) {
    this._hasLoadedPCs.next(false);
    const data = await this.api.ListProductContent(nextToken);
    if (!data) {
      return;
    }

    this.dataStore.productContents = (
      this.dataStore.productContents || []
    ).concat(
      (data.items || []).map((item) => ({ id: item.id, name: item.searchKey }))
    );

    this.dataStore.productContents = uniqBy(
      this.dataStore.productContents,
      'id'
    );
    this._productContents.next(
      Object.assign({}, this.dataStore).productContents
    );

    if (data.nextToken) {
      await this.loadProductContent(data.nextToken);
    }

    this._hasLoadedPCs.next(true);
  }

  getProductContent(key: string) {
    const productContent = this.dataStore.productContents.find(
      (product) => product.name === key
    );
    return productContent;
  }

  resetWashRequests() {
    this.dataStore.washRequests = [];
    this._washRequests.next(Object.assign({}, this.dataStore).washRequests);
  }

  async existWashRequest(
    tankNumber: string,
    operatedBy: string,
    lastUpdateTime: number
  ) {
    const data = await this.api.GetExistingWashRequests(
      tankNumber,
      operatedBy,
      lastUpdateTime
    );
    return data.items;
  }

  private async getWashRequestListByOptionAsDispatcher(
    // tslint:disable-next-line: no-shadowed-variable
    option: MenuOption,
    businessPartner: string,
    from: number = 0,
    filter?: Filter,
    sort?: Sort,
    onlyMine?: Filter[]
  ) {
    const queries = [];
    const rangeQueryThreeMonths = new SearchRange('lastUpdateTime', [
      {
        rangeType: 'gt',
        rangeTime: Math.round(
          (Date.now() - THREE_MONTHS_AGO_TIME_STAMP) / 1000
        ),
      },
    ]);

    const rangeQueryFifteenMinutes = new SearchRange('completeTime', [
      {
        rangeType: 'gte',
        rangeTime: Math.round(
          (Date.now() - FIFTEEN_MINUTES_AGO_TIME_STAMP) / 1000
        ),
      },
    ]);

    const rangeQueryBetweenFifteenMinutesAndThreeMonths = new SearchRange(
      'completeTime',
      [
        {
          rangeType: 'lt',
          rangeTime: Math.round(
            (Date.now() - FIFTEEN_MINUTES_AGO_TIME_STAMP) / 1000
          ),
        },
        {
          rangeType: 'gt',
          rangeTime: Math.round(
            (Date.now() - THREE_MONTHS_AGO_TIME_STAMP) / 1000
          ),
        },
      ]
    );

    switch (option) {
      case MenuOption.Draft:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['DRAFT']),
              new SearchDefinition(
                'createdByRole',
                ['customerDispatcher', 'customerAll'],
                'OR'
              ),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            'createdByBulkUpload',
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Submitted:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['SUBMITTED']),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['UPDATED']),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['DRAFT']),
              new SearchDefinition('createdByBulkUpload', [true]),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.NeedsAction:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['REVIEW_NEEDED']),
              new SearchDefinition(
                'createdByRole',
                ['customerDispatcher', 'customerAll'],
                'OR'
              ),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['ON_HOLD']),
              new SearchDefinition('operatedBy', [businessPartner]),
              new SearchDefinition('pendingHeelApproval', [true]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['SUBMITTED']),
              new SearchDefinition(
                'createdByRole',
                ['CSC', 'Manager', 'Ticket', 'Cleaner'],
                'OR'
              ),
              new SearchDefinition('holdRemoved', ['NOT true']),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition(
                'status',
                ['STARTED', 'COMPLETED', 'PAUSED'],
                'OR'
              ),
              new SearchDefinition('pendingHeelApproval', [true]),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Pending:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['CREDIT_HOLD']),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Accepted:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['ACCEPTED', 'SCHEDULED'], 'OR'),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.InProgress:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['STARTED', 'PAUSED'], 'OR'),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['ON_HOLD']),
              new SearchDefinition('operatedBy', [businessPartner]),
              new SearchDefinition('pendingHeelApproval', ['NOT true']),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['COMPLETED']),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryFifteenMinutes
          )
        );
        break;
      case MenuOption.Completed:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['COMPLETED']),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryBetweenFifteenMinutesAndThreeMonths
          )
        );
        break;
      case MenuOption.Canceled:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['CANCELED']),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Rejected:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['REJECTED']),
              new SearchDefinition('operatedBy', [businessPartner]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
    }
    this.zone.run(async () => {
      const data = await this.elasticsearchService.searchWashRequest(
        queries,
        from,
        filter,
        sort,
        onlyMine
      );
      if (from === 0) {
        this.dataStore.washRequests = [];
      }
      this.dataStore.washRequests = this.dataStore.washRequests.concat(
        data.list.map((item) => new WashRequest(item))
      );
      this._washRequests.next(Object.assign({}, this.dataStore).washRequests);
      this.markWashRequesAsRead(data.list);
    });
  }

  addOne() {
    this.dataStore.washRequests.push(this.dataStore.washRequests[0]);
    this._washRequests.next(Object.assign({}, this.dataStore).washRequests);
  }

  private getShouldQueryByLastScheduledStartTime(
    expiredRequest: boolean = false
  ) {
    const operator = expiredRequest ? 'lte' : 'gt';
    return new SearchShouldDefinition(
      [
        {
          bool: {
            must_not: {
              exists: {
                field: 'lastScheduledStartTime',
              },
            },
            filter: {
              range: {
                needByTime: {
                  [operator]: YESTERDAY_TIMESTAMP,
                },
              },
            },
          },
        },
        {
          range: {
            lastScheduledStartTime: {
              [operator]: YESTERDAY_TIMESTAMP,
            },
          },
        },
      ],
      1
    );
  }

  private async getWashRequestListByOption(
    // tslint:disable-next-line: no-shadowed-variable
    option: MenuOption,
    terminal: string,
    from: number = 0,
    filter: Filter,
    sort?: Sort
  ) {
    const queries = [];
    const rangeQueryThreeMonthsSchneider = new SearchRange('lastUpdateTime', [
      {
        rangeType: 'gt',
        rangeTime: Math.round(
          (Date.now() - THREE_MONTHS_AGO_TIME_STAMP) / 1000
        ),
      },
      {
        rangeTime: SCHNDEIDER_COMPLETION_START_DATE,
        rangeType: 'gt',
      },
    ]);
    const rangeQueryThreeMonths = new SearchRange('lastUpdateTime', [
      {
        rangeType: 'gt',
        rangeTime: Math.round(
          (Date.now() - THREE_MONTHS_AGO_TIME_STAMP) / 1000
        ),
      },
    ]);

    const shouldBeRecentQuery = this.getShouldQueryByLastScheduledStartTime();
    const shouldBeExpiredQuery =
      this.getShouldQueryByLastScheduledStartTime(true);

    switch (option) {
      case MenuOption.Draft:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['DRAFT']),
              new SearchDefinition(
                'createdByRole',
                ['CSC', 'Manager', 'Ticket', 'Cleaner'],
                'OR'
              ),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            'createdByBulkUpload',
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.NewWashRequests:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['SUBMITTED']),
              new SearchDefinition(
                'createdByRole',
                ['customerDispatcher', 'customerAll'],
                'OR'
              ),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['SUBMITTED']),
              new SearchDefinition('holdRemoved', [true]),
              new SearchDefinition(
                'createdByRole',
                ['CSC', 'Manager', 'Ticket', 'Cleaner'],
                'OR'
              ),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['DRAFT']),
              new SearchDefinition('createdByBulkUpload', [true]),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.NeedsAction:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['UPDATED']),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['REVIEW_NEEDED']),
              new SearchDefinition(
                'createdByRole',
                ['CSC', 'Manager', 'Ticket', 'Cleaner'],
                'OR'
              ),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Pending:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['SUBMITTED']),
              new SearchDefinition(
                'createdByRole',
                ['CSC', 'Manager', 'Ticket', 'Cleaner'],
                'OR'
              ),
              new SearchDefinition('holdRemoved', ['NOT true']),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['REVIEW_NEEDED']),
              new SearchDefinition(
                'createdByRole',
                ['CustomerDispatcher', 'CustomerAll'],
                'OR'
              ),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['STARTED', 'COMPLETED'], 'OR'),
              new SearchDefinition('pendingHeelApproval', [true]),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Hold:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['ON_HOLD']),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Accepted:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['ACCEPTED', 'SCHEDULED'], 'OR'),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths,
            shouldBeRecentQuery
          )
        );
        break;
      case MenuOption.CreditHold:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['CREDIT_HOLD']),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.InProgress:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['STARTED', 'PAUSED'], 'OR'),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths,
            shouldBeRecentQuery
          )
        );
        break;
      case MenuOption.Completed:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['COMPLETED']),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Canceled:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['CANCELED']),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Rejected:
        queries.push(
          new Query(
            [
              new SearchDefinition('status', ['REJECTED']),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths
          )
        );
        break;
      case MenuOption.Expired:
        queries.push(
          new Query(
            [
              new SearchDefinition(
                'status',
                ['ACCEPTED', 'SCHEDULED', 'STARTED', 'PAUSED'],
                'OR'
              ),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonths,
            shouldBeExpiredQuery
          )
        );
        break;
      case MenuOption.SchneiderPortalCompletions:
        queries.push(
          new Query(
            [
              new SearchDefinition('workOrderStatus', ['Completed']),
              new SearchDefinition('operatedById', [
                '4417EB2F70E84219B4420854FA261FB2',
              ]),
              new SearchDefinition('terminal', [terminal]),
            ],
            null,
            null,
            rangeQueryThreeMonthsSchneider
          )
        );
        break;
    }

    await this.zone.run(async () => {
      const data = await this.elasticsearchService.searchWashRequest(
        queries,
        from,
        filter,
        sort
      );
      if (from === 0) {
        this.dataStore.washRequests = [];
      }
      this.dataStore.washRequests = this.dataStore.washRequests.concat(
        data.list.map((item) => new WashRequest(item))
      );
      this._washRequests.next(Object.assign({}, this.dataStore).washRequests);

      this.markWashRequesAsRead(data.list);
    });
  }

  async create(washRequest: WashRequest) {
    const { user } = this.authService;
    const options = {
      headers: this.defaultHeaders,
      body: {
        washRequest,
        user: {
          currentRole: user.currentRole,
          userName: user.username,
          businessPartnerName: user.businessPartnerName,
        },
      },
    };

    if (document.cookie.includes('enableFastWoConversion=true')) {
      washRequest.enableFastWoConversion = true;
    }

    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/wash-request',
      options,
    } as unknown as AmplifyRequestInput<'post'>);
  }

  async update(washRequest: WashRequest) {
    const { user } = this.authService;
    const options = {
      headers: this.defaultHeaders,
      body: {
        washRequest,
        user: {
          currentRole: user.currentRole,
          userName: user.username,
          businessPartnerName: user.businessPartnerName,
        },
      },
    };

    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/wash-request/update',
      options,
    } as unknown as AmplifyRequestInput<'post'>);
  }

  async updateSpecialPrep(id: string, specialPrep: boolean) {
    return this.amplifyApiService.request('put', {
      apiName: 'PortalAPI',
      path: '/wash-request/special-prep',
      options: {
        headers: this.defaultHeaders,
        body: {
          id,
          specialPrep,
        },
      },
    } as unknown as AmplifyRequestInput<'put'>);
  }

  submitToApproval(id: string) {
    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'PortalAPI',
          path: '/wash-request/submit',
          options: {
            headers: this.defaultHeaders,
            body: {
              id,
            },
          },
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (res: ResponseBody) =>
            this.removeWashRequestFromDataStore(res.data.washRequest),
        })
      );
  }

  accept(id: string, preventOrderConversion?: boolean) {
    const options = {
      headers: this.defaultHeaders,
      body: {
        id,
        userId: 'tuser',
      },
    };

    if (preventOrderConversion) {
      options.body['preventOrderConversion'] = preventOrderConversion;
    }

    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'PortalAPI',
          path: '/wash-request/accept',
          options,
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (data: WashRequest) =>
            this.removeWashRequestFromDataStore(data),
        })
      );
  }

  cancel(id: string) {
    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'PortalAPI',
          path: '/wash-request/cancel',
          options: {
            headers: this.defaultHeaders,
            body: {
              id,
              userId: 'tuser', // TODO: Replace fixed user
            },
          },
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (data: WashRequest) =>
            this.removeWashRequestFromDataStore(data),
        })
      );
  }

  reject(id: string, reason: any, rejectWithHeel?: boolean) {
    const options = {
      headers: this.defaultHeaders,
      body: {
        id,
        rejectionReason: { code: reason.code, label: reason.label },
        rejectionData: {},
      },
    };

    if (rejectWithHeel) {
      options.body['rejectionData'] = {
        heelAmount: reason.heelAmount,
      };
    }

    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'PortalAPI',
          path: '/wash-request/reject',
          options,
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (data: WashRequest) =>
            this.removeWashRequestFromDataStore(data),
        })
      );
  }

  shortenedCompletion(
    id: string,
    manualStartStopData?: ManualStartStopData,
    operatorId?: string
  ) {
    return this.amplifyApiService.request(
      'post',
      {
        apiName: 'OnTraxAPI',
        path: '/wash-request/shortened-completion',
        options: {
          headers: this.defaultHeaders,
          body: {
            id,
            manualStartStopData,
            operatorId,
          },
        },
      } as unknown as AmplifyRequestInput<'post'>,
      true
    );
  }

  forceAutoSchedule(id: string) {
    return this.amplifyApiService.request(
      'post',
      {
        apiName: 'OnTraxAPI',
        path: '/wash-request/schedule',
        options: {
          headers: this.defaultHeaders,
          body: {
            id,
          },
        },
      } as unknown as AmplifyRequestInput<'post'>,
      true
    );
  }

  async getReasonsForMissedNeedByTime() {
    const data = await this.api.ListMissedNeedByTimeReason();
    return data.items;
  }

  async getReasonsForReject() {
    const data = await this.api.ListRejectionReason();
    return data.items;
  }

  clone(id: string) {
    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/wash-request/clone',
      options: {
        headers: this.defaultHeaders,
        body: {
          id,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  openCreateForm(component: ComponentType<any>) {
    const overlayConfig = this.getOverlayConfig();
    this.createWashOverlayDialogRef = this.overlay.create(overlayConfig);

    // Create ComponentPortal that can be attached to a PortalHost
    const filePreviewPortal = new ComponentPortal(component);

    // Attach ComponentPortal to PortalHost
    this.createWashOverlayDialogRef.attach(filePreviewPortal);
  }

  closeCreateForm() {
    if (this.createWashOverlayDialogRef) {
      this.createWashOverlayDialogRef.dispose();
    }
  }

  removeWashRequestFromDataStore(washRequest: WashRequest) {
    const index = this.dataStore.washRequests.findIndex(
      (item) => item.id === washRequest.id
    );
    if (index >= 0) {
      this.dataStore.washRequests.splice(index, 1);
      this._washRequests.next(Object.assign({}, this.dataStore).washRequests);
    }
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      panelClass: 'popover-panel',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  requestHeelApproval(washRequestId: string, heel: Heel) {
    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/wash-request/request-heel-approval',
      options: {
        headers: this.defaultHeaders,
        body: {
          heel,
          id: washRequestId,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  approveHeel(washRequestId: string, comment: string) {
    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/wash-request/heel/approve',
      options: {
        headers: this.defaultHeaders,
        body: {
          comment,
          id: washRequestId,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  denyHeel(washRequestId: string, comment: string) {
    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/wash-request/heel/deny',
      options: {
        headers: this.defaultHeaders,
        body: {
          comment,
          id: washRequestId,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  requestInfo(washRequestId: string, comment: string) {
    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/wash-request/heel/request-info',
      options: {
        headers: this.defaultHeaders,
        body: {
          comment,
          id: washRequestId,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  provideInfo(
    washRequestId: string,
    comment: string,
    uploadedFiles: Array<any>
  ) {
    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/wash-request/heel/provide-info',
      options: {
        headers: this.defaultHeaders,
        body: {
          comment,
          id: washRequestId,
          files: uploadedFiles,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  changeBreakpointFlag(flag: boolean) {
    this._breakpointFlag.next(flag);
  }

  removeHold(washRequestId: string) {
    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'PortalAPI',
          path: '/wash-request/remove-hold',
          options: {
            headers: this.defaultHeaders,
            body: {
              id: washRequestId,
            },
          },
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (data: WashRequest) => {
            this.removeWashRequestFromDataStore(data);
          },
        })
      );
  }

  retrySubmit(id: string) {
    return this.amplifyApiService
      .request(
        'post',
        {
          apiName: 'PortalAPI',
          path: '/wash-request/retry-submit',
          options: {
            headers: this.defaultHeaders,
            body: { id },
          },
        } as unknown as AmplifyRequestInput<'post'>,
        true
      )
      .pipe(
        tap({
          next: (data: WashRequest) => {
            this.removeWashRequestFromDataStore(data);
          },
        })
      );
  }

  checkIfCanConvertWashRequest(
    washRequestId: string,
    newServiceType: ServiceType
  ) {
    return this.amplifyApiService.request('get', {
      apiName: 'PortalAPI',
      path: `/wash-request/service-type?washRequestId=${washRequestId}&newServiceType=${newServiceType.key}`,
      options: {
        headers: this.defaultHeaders,
      },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  convertWashRequest(
    washRequestId: string,
    newServiceType: ServiceType,
    poNumber: string,
    poNumberForPrep?: string,
    createNewWashRequest?: boolean
  ) {
    return this.amplifyApiService.request('put', {
      apiName: 'PortalAPI',
      path: '/wash-request/service-type',
      options: {
        headers: this.defaultHeaders,
        body: {
          washRequestId,
          newServiceType: newServiceType.key,
          poNumber,
          poNumberForPrep,
          createNewWashRequest,
        },
      },
    } as unknown as AmplifyRequestInput<'put'>);
  }

  getCommonProduct(contentsId: { contentId: string }[], terminalId: string) {
    return this.amplifyApiService.request('post', {
      apiName: 'OnTraxAPI',
      path: '/get-common-product',
      options: {
        headers: this.defaultHeaders,
        body: {
          contentsId,
          terminalId,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  getServicePlanData(washRequest: WashRequest) {
    let body = {};

    const defaultBody = {
      terminalId: washRequest.terminalId,
      operatedById: washRequest.customerId,
      serviceTypeId: washRequest.serviceTypeId,
      tankId: washRequest.containerId,
      foodGrade: !!washRequest.foodGrade,
      kosher: !!washRequest.kosher,
      createTime: washRequest.createTime,
      exteriorWash: !!washRequest.exteriorWash,
      tankTypeId: washRequest.containerTypeId,
      serviceTypeOntraxId: washRequest.serviceTypeOntraxId,
    };

    if (washRequest.foodGrade) {
      body = {
        ...defaultBody,
        lastContainedProduct1Id: washRequest.lastContainedProduct1Id,
        lastContainedProduct2Id: washRequest.lastContainedProduct2Id,
        lastContainedProduct3Id: washRequest.lastContainedProduct3Id,
      };
    } else {
      body = {
        ...defaultBody,
        lastContainedProductComp1Id: washRequest.lastContainedProductComp1Id,
        lastContainedProductComp2Id: washRequest.lastContainedProductComp2Id,
        lastContainedProductComp3Id: washRequest.lastContainedProductComp3Id,
        lastContainedProductComp4Id: washRequest.lastContainedProductComp4Id,
        lastContainedProductComp5Id: washRequest.lastContainedProductComp5Id,
      };
    }

    return this.amplifyApiService.request('post', {
      apiName: 'OnTraxAPI',
      path: '/get-service-plan',
      options: {
        headers: this.defaultHeaders,
        body,
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  markWashRequesAsRead(newWashRequest) {
    const { user } = this.authService;
    const notVizualizedWr = newWashRequest.filter(
      (item) =>
        !Array.isArray(item.visualizedBy) ||
        !item.visualizedBy.includes(user.id)
    );
    const washRequestIds = notVizualizedWr.map((item) => item.id);

    if (washRequestIds.length > 0) {
      return this.amplifyApiService.request('post', {
        apiName: 'SearchAPI',
        path: '/wash-request/mark-as-read',
        options: {
          headers: this.defaultHeaders,
          body: {
            washRequestIds,
            userId: user.id,
          },
        },
      } as unknown as AmplifyRequestInput<'post'>);
    }
  }

  getAverageAutomatedConversionTime() {
    return this.amplifyApiService.request('get', {
      apiName: 'OnTraxAPI',
      path: '/average-conversion-time',
      options: {
        headers: this.defaultHeaders,
      },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  refreshCount(options: string[]) {
    const { user } = this.authService;
    const params: any = { userId: user.id };
    if (this.authService.hasDispatcherRole()) {
      params.type = 'businessPartner';
      params.value = user.businessPartnerName;
    } else {
      params.type = 'terminal';
      params.value = user.currentTerminal.key;
    }

    if (options.length === 0) {
      throw new Error('refreshCount() options can not be empty');
    }

    const menuOptions = options.join(',');

    params.menuOptions = menuOptions;

    this.amplifyApiService
      .request('get', {
        apiName: 'SearchAPI',
        path: '/wash-request/count',
        options: {
          headers: this.defaultHeaders,
          queryParams: params,
        },
      } as unknown as AmplifyRequestInput<'get'>)
      .then((data: CountMap) => {
        this._countMap.next(data);
      });
  }

  createTank(tank: Tank) {
    return this.amplifyApiService.request('post', {
      apiName: 'PortalAPI',
      path: '/create-tank',
      options: {
        headers: this.defaultHeaders,
        body: tank,
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  cse(confinedSpaceEntryData) {
    const {
      confinedEntry,
      workPerformedBy,
      confinedEntryType,
      washRequestId,
      confinedEntryFiles,
    } = confinedSpaceEntryData;

    return this.amplifyApiService.request('post', {
      apiName: 'OnTraxAPI',
      path: '/save-confined-space-entry',
      options: {
        headers: this.defaultHeaders,
        body: {
          confinedEntry,
          workPerformedBy,
          confinedEntryType,
          id: washRequestId,
          confinedEntryFiles,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  shouldDisplayEctForTerminal(actualTerminal) {
    return this.terminalsWithEctEnabled.some(
      (terminal) => actualTerminal === terminal
    );
  }

  getTerminalSearchKey(terminalId) {
    if (this.authService.hasDispatcherRole()) {
      const terminal = this.warehouseService.dataStore.warehouses.find(
        (item) => item.id === terminalId
      );
      return terminal.searchKey;
    }
    return this.authService.user.currentTerminal.key;
  }

  async getReasonsOfChange(nextToken?: string) {
    const data = await this.api.ListReasonOfChange(nextToken);
    if (!data) {
      return;
    }

    this.dataStore.reasonOfChangeOptions = (
      this.dataStore.reasonOfChangeOptions || []
    )
      .concat(data.items || [])
      .map((item) => ({ code: item.code, displayName: item.displayName }));
    this.dataStore.reasonOfChangeOptions = uniqBy(
      this.dataStore.reasonOfChangeOptions,
      'code'
    );
    this._reasonOfChangeOptions.next(
      Object.assign({}, this.dataStore).reasonOfChangeOptions
    );

    if (data.nextToken) {
      await this.getReasonsOfChange(data.nextToken);
    }
  }

  updateWashRequestsConversionStatuses(
    requests?: {
      requestId?: string;
      conversionStatus?: string;
      conversionMessage?: string;
    }[]
  ) {
    requests.map((updatedRequest) => {
      const washRequest = this.dataStore.washRequests.find(
        (washRequestItem) => washRequestItem.id === updatedRequest.requestId
      );

      if (washRequest) {
        washRequest.orderConversionStatus =
          updatedRequest.conversionStatus as OrderConversionStatuses;
        washRequest.orderConversionMessage =
          washRequest.orderConversionMessage ||
          updatedRequest.conversionMessage;
        this.updateWashRequestInDataStore(washRequest);
      }
    });
  }

  updateWashRequestsSchneiderCompletionStatuses(
    requests?: {
      requestId?: string;
      completionStatus?: string;
      completionMessage?: string;
    }[]
  ) {
    requests.map((updatedRequest) => {
      const washRequest = this.dataStore.washRequests.find(
        (washRequestItem) => washRequestItem.id === updatedRequest.requestId
      );

      if (washRequest) {
        washRequest.schneiderCompletionStatus =
          updatedRequest.completionStatus as SchneiderCompletionStatus;
        washRequest.schneiderCompletionMessage =
          washRequest.schneiderCompletionMessage ||
          updatedRequest.completionMessage;
        this.updateWashRequestInDataStore(washRequest);
      }
    });
  }

  async loadExteriorWashProducts(nextToken?: string) {
    const data = await this.api.ListCustomerProductTypes(nextToken);
    if (!data) {
      return;
    }

    this.dataStore.exteriorWashProducts = (
      this.dataStore.exteriorWashProducts || []
    )
      .concat(data.items || [])
      .map((item) => ({
        containerTypeId: item.containerTypeId,
        containerTypeName: item.containerTypeName,
        productId: item.productId,
        productName: item.productName,
        productCategoryName: item.productCategoryName,
        containerCategoryName: item.containerCategoryName,
        customerProductType: item.customerProductType,
      }));
    this._exteriorWashProductsOptions.next(
      Object.assign({}, this.dataStore).exteriorWashProducts
    );

    if (data.nextToken) {
      await this.loadExteriorWashProducts(data.nextToken);
    }
  }

  hasExteriorWashByDefault(customerId: string) {
    const exteriorWashByDefault = businessPartnerWithExteriorWashByDefault.some(
      (item) => item.id === customerId
    );

    if (exteriorWashByDefault) {
      return true;
    } else {
      return false;
    }
  }

  handleExteriorWashOfferAction(
    userId: string,
    offerId: string,
    offerStatus: string
  ) {
    return this.amplifyApiService.request('put', {
      apiName: 'ExteriorWashOfferAPI',
      path: '/response',
      options: {
        headers: this.defaultHeaders,
        body: {
          userId,
          offerId,
          offerStatus,
        },
      },
    } as unknown as AmplifyRequestInput<'put'>);
  }

  isFoodGradeOnly(tankTypeName: string, canBeFoodGrade: boolean) {
    const foodGradeWord = 'foodgrade';

    return canBeFoodGrade && tankTypeName.toLowerCase().includes(foodGradeWord);
  }

  isCreatedByEtendo(createdByEtendo: boolean, workOrderId?: string): boolean {
    return createdByEtendo === true && !!workOrderId;
  }

  buildEtendoUrl(createdByEtendo, workOrderId, washRequestIdOpenBravo) {
    const baseUrl = environment.openBravoBaseUrl;

    const tabId =
      createdByEtendo && workOrderId ? WORK_ORDER_TAB_ID : SALES_ORDER_TAB_ID;
    const recordId =
      createdByEtendo && workOrderId ? workOrderId : washRequestIdOpenBravo;

    if (!tabId || !recordId) {
      console.error('Invalid URL parameters');
      return null;
    }

    return `${baseUrl}?tabId=${tabId}&recordId=${recordId}`;
  }

  openEtendoUrl(
    washRequestIdOpenBravo: string,
    workOrderId: string,
    createdByEtendo: boolean
  ) {
    const url = this.buildEtendoUrl(
      createdByEtendo,
      workOrderId,
      washRequestIdOpenBravo
    );
    if (url) {
      window.open(url);
    } else {
      console.error('Failed to open URL due to invalid parameters');
    }
  }

  getEtendoButtonLabel(workOrderId: string, createdByEtendo: boolean): string {
    return this.isCreatedByEtendo(createdByEtendo, workOrderId)
      ? WORK_ORDER_BTN_LABEL
      : SALES_ORDER_BTN_LABEL;
  }
}
