import { ResourcesConfig } from 'aws-amplify';

const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: 'us-east-1:11f36642-68c4-4fd5-bd93-8149979d1895',
      userPoolId: 'us-east-1_K9h8KnmA6',
      userPoolClientId: '78fisir7hah1suosfr9n8bn2sb',
    },
  },
  API: {
    REST: {
      AuthAPI: {
        endpoint: 'https://api.stg.ontrax.website/auth',
        region: 'us-east-1',
      },
      PortalAPI: {
        endpoint: 'https://api.stg.ontrax.website/portal',
        region: 'us-east-1',
      },
      OnTraxAPI: {
        endpoint: 'https://api.stg.ontrax.website/ontrax',
        region: 'us-east-1',
      },
      WarehouseAPI: {
        endpoint: 'https://api.stg.ontrax.website/warehouse',
        region: 'us-east-1',
      },
      ReportsAPI: {
        endpoint: 'https://api.stg.ontrax.website/reports',
        region: 'us-east-1',
      },
      ShiftLogsAPI: {
        endpoint: 'https://api.stg.ontrax.website/shift-logs',
        region: 'us-east-1',
      },
      SearchAPI: {
        endpoint: 'https://api.stg.ontrax.website/search',
        region: 'us-east-1',
      },
      Elasticsearch: {
        endpoint:
          'https://search-stg-quala-yu3iadwprqjxrbi4ojmdzrn3f4.us-east-1.es.amazonaws.com',
        region: 'us-east-1',
        service: 'es',
      },
      NotificationPreferencesAPI: {
        endpoint: 'https://api.stg.ontrax.website/notification-preferences',
        region: 'us-east-1',
      },
      NonConformityReportAPI: {
        endpoint: 'https://api.stg.ontrax.website/ncr',
        region: 'us-east-1',
      },
      ExteriorWashOfferAPI: {
        endpoint: 'https://api.stg.ontrax.website/exterior-wash-offer',
        region: 'us-east-1',
      },
    },
    GraphQL: {
      endpoint:
        'https://2sbm5fls7raxnmc75ituc4af7u.appsync-api.us-east-1.amazonaws.com/graphql',
      defaultAuthMode: 'iam',
      region: 'us-east-1',
    },
  },
  Storage: {
    S3: {
      bucket: 'stg-quala-portal-authenticated-user-upload',
    },
  },
};

export const environment = {
  production: false,
  region: 'us-east-1',
  baseUrl: 'https://api.stg.ontrax.website/portal',
  settingsBaseUrl: 'https://api.stg.ontrax.website/warehouse',
  searchBaseUrl: 'https://api.stg.ontrax.website/search',
  elasticsearchBaseUrl:
    'https://search-stg-quala-yu3iadwprqjxrbi4ojmdzrn3f4.us-east-1.es.amazonaws.com',
  elasticsearchIndice: 'stg_wash_request',
  elasticsearchIndiceNcr: 'ncr',
  openBravoBaseUrl: 'https://obpreprod.quala.us.com/erp',
  publicAppSyncAPI:
    'https://67j3hy47d5egbog7jvgseln47e.appsync-api.us-east-1.amazonaws.com/graphql',
  amplifyConfig,
  amplifyCustomConfig: {
    cookieStorageData: {
      domain: window.location.hostname.includes('localhost')
        ? 'localhost'
        : 'ontrax.website',
      secure: true,
      path: '/',
      expires: 365,
    },
  },
  // Fill after creation of this resource in STG
  systemMonitor: {
    baseUrl: 'https://api.stg.ontrax.website/system',
    apiKeyName: 'stg-quala-system-monitor-api-key',
    apiKeyValue: 'yNffL8MMnE49Kckupwx3m55NH0lsyRog2FgZM93J',
  },
  reports: {
    baseUrl: 'https://api.stg.ontrax.website/reports',
    reportIds: {
      shiftLogs: '6aac62f5-78ea-4b3f-98c3-3fb3f5b5bebf',
      terminalReports: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      customerWashMetrics: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      ehsWasteManagement: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      leanDailyManagement: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      terminalScorecardDepot: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      terminalScorecardIndustrial: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      terminalScorecardRail: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
      terminalScorecardWash: 'e25b51a5-c8bd-4d5c-8632-629548cf5aef',
    },
  },
  bulkTemplate: {
    downloadUrl:
      'https://prd-quala-wash-template.s3-us-west-2.amazonaws.com/quala-ontrax-bulk-upload-template.xlsx',
  },
  gtmIdentification: 'GTM-MD3WC49J',
  wikiUrl: 'https://sites.google.com/a/quala.us.com/quala-ontrax-wiki/',
  keycloak: {
    provider: 'loginpreprod.quala.us.com/auth/realms/master',
    issuer: 'https://loginpreprod.quala.us.com/auth/',
    realm: 'master',
    clientId: 'ontrax',
  },
  trainingVideoUrl:
    'https://youtube.com/playlist?list=PLMMT00bdm-ENZStzynM3_KlE1oekuwgWD',
  hotjar: {
    siteId: 3443954,
    version: 6,
  },
  reportsPortal: {
    url: 'https://dev.reports.ontrax.website',
  },
  cognito: {
    domainUrl: 'https://stg-ontrax.auth.us-east-1.amazoncognito.com',
    tokenUrl:
      'https://stg-ontrax.auth.us-east-1.amazoncognito.com/oauth2/token',
    redirectUri: `${window.location.origin}/cognito-callback`,
    logoutUri: window.location.origin,
  },
  redirectToNewLoginPage: true,
  allowedIframeOrigins: [
    'http://localhost:4201',
    'https://stg.reports.ontrax.website',
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
