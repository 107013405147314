import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  NonConformityReport,
  ReducedNonConformityReport,
} from './non-conformity-report.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { BusinessPartner } from '../wash-list/wash-list.model';
import { ElasticsearchService } from 'src/app/core/elasticsearch.service';
import { User } from '../../core/model/user.model';
import {
  Filter,
  Query,
  SearchDefinition,
  Sort,
} from 'src/app/core/model/search-definition.model';
import { Warehouse } from 'src/app/core/model/warehouse.model';
import {
  AmplifyApiService,
  AmplifyRequestInput,
} from 'src/app/core/services/amplify-api.service';
interface DataStore {
  businessPartners: BusinessPartner[];
  nonConformityReports: ReducedNonConformityReport[];
  allManagers: User[];
}
@Injectable({
  providedIn: 'root',
})
export class NcrService {
  private defaultHeaders: any;

  public dataStore: DataStore = {
    businessPartners: [],
    nonConformityReports: [],
    allManagers: [],
  };
  constructor(
    private authService: AuthService,
    private amplifyApiService: AmplifyApiService,
    private zone: NgZone,
    private elasticsearchService: ElasticsearchService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };

    if (this.authService.user) {
      this.defaultHeaders['x-ontrax-identity'] =
        `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`;
    }

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.defaultHeaders['x-ontrax-identity'] =
          `${user.username};${user.currentRoleAcronym}`;
      }
    });
  }
  private _managers = new BehaviorSubject<User[]>(this.dataStore.allManagers);
  public managers$: Observable<User[]> = this._managers.asObservable();

  private _nonConformityReports = new BehaviorSubject<
    ReducedNonConformityReport[]
  >([]);
  readonly nonConformityReports = this._nonConformityReports.asObservable();

  removeNonConformityReportsFromDataStore(ncr: ReducedNonConformityReport) {
    const index = this.dataStore.nonConformityReports.findIndex(
      (item) => item.id === ncr.id
    );
    if (index >= 0) {
      this.dataStore.nonConformityReports.splice(index, 1);
      this._nonConformityReports.next(
        Object.assign({}, this.dataStore).nonConformityReports
      );
    }
  }

  async getNcrList(
    ncrStatus: string,
    from: number = 0,
    filter?: Filter,
    sort?: Sort
  ) {
    const businessPartnerId = this.authService.user.businessPartnerId;
    const customerMasterId = this.authService.user.customerMasterId;
    const query = [];

    if (
      this.authService.hasFullReportAccess() ||
      this.authService.hasRVPDepotRole()
    ) {
      query.push(
        new Query(
          [new SearchDefinition('status', [ncrStatus])],
          null,
          null,
          null,
          null
        )
      );
    } else if (this.authService.hasDispatcherRole() && customerMasterId) {
      query.push(
        new Query(
          [
            new SearchDefinition('status', [ncrStatus]),
            new SearchDefinition('customerMasterId', [customerMasterId]),
          ],
          null
        )
      );
    } else if (this.authService.hasDispatcherRole() && !customerMasterId) {
      query.push(
        new Query(
          [
            new SearchDefinition('status', [ncrStatus]),
            new SearchDefinition('businessPartnerId', [businessPartnerId]),
          ],
          null
        )
      );
    } else if (this.authService.hasQualaWorkerRole()) {
      const userId = this.authService.user.id;

      query.push(
        new Query(
          [
            new SearchDefinition('status', [ncrStatus]),
            new SearchDefinition('assignToId', [userId]),
          ],
          null
        )
      );
    }
    this.zone.run(async () => {
      const data = await this.elasticsearchService.searchNcr(
        query,
        from,
        filter,
        sort
      );

      if (from === 0) {
        this.dataStore.nonConformityReports = [];
      }
      this.dataStore.nonConformityReports =
        this.dataStore.nonConformityReports.concat(
          data.list.map((item) => new ReducedNonConformityReport(item))
        );
      this._nonConformityReports.next(
        Object.assign({}, this.dataStore).nonConformityReports
      );
    });
  }

  getNCRById(ncrId: string) {
    return this.amplifyApiService.request('get', {
      apiName: 'NonConformityReportAPI',
      path: `/${ncrId}`,
      options: {
        headers: this.defaultHeaders,
      },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  approveNCR(
    ncr: ReducedNonConformityReport,
    resolutionToBeDetermined: boolean
  ) {
    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'NonConformityReportAPI',
          path: '/approve',
          options: {
            headers: this.defaultHeaders,
            body: {
              ncr,
              username: this.authService.user.username,
              resolutionToBeDetermined,
            },
          },
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (data: any) => {
            this.removeNonConformityReportsFromDataStore(
              data as ReducedNonConformityReport
            );
          },
        })
      );
  }

  cancelNCR(ncrId: string, cancelReason: string) {
    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'NonConformityReportAPI',
          path: '/cancel',
          options: {
            headers: this.defaultHeaders,
            body: {
              ncrId,
              cancelReason,
              username: this.authService.user.username,
            },
          },
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (data: any) => {
            this.removeNonConformityReportsFromDataStore(
              data as ReducedNonConformityReport
            );
          },
        })
      );
  }

  updateNcr(ncr: any, username: string): Promise<any> {
    return this.amplifyApiService.request('put', {
      apiName: 'NonConformityReportAPI',
      path: '/update',
      options: {
        headers: this.defaultHeaders,
        body: {
          ncr,
          username,
        },
      },
    } as unknown as AmplifyRequestInput<'put'>);
  }

  createNCR(ncr: NonConformityReport): Promise<NonConformityReport> {
    return this.amplifyApiService.request('post', {
      apiName: 'NonConformityReportAPI',
      path: '/creation',
      options: {
        headers: this.defaultHeaders,
        body: ncr,
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  async createExternalNCR(nonConformityReport: NonConformityReport) {
    return this.amplifyApiService.request('post', {
      apiName: 'NonConformityReportAPI',
      path: '/creation',
      options: {
        headers: { 'Content-Type': 'application/json' },
        body: nonConformityReport,
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  getInformationFromWorkOrder(workOrder: string) {
    return this.amplifyApiService.request('get', {
      apiName: 'OnTraxAPI',
      path: `/work-order/${workOrder}`,
      options: {
        headers: this.defaultHeaders,
      },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  async getUsersFromWarehouse(terminalNumber: string) {
    const usersData = (await this.amplifyApiService.request('get', {
      apiName: 'OnTraxAPI',
      path: '/quala-managers',
      options: {
        headers: this.defaultHeaders,
        queryParams: {
          terminalNumber,
        },
      },
    } as unknown as AmplifyRequestInput<'get'>)) as Array<any>;

    return usersData.map((json) => new User(json));
  }

  assignNCR(ncr: ReducedNonConformityReport) {
    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'NonConformityReportAPI',
          path: '/assign',
          options: {
            headers: this.defaultHeaders,
            body: {
              ncr,
            },
          },
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (data: any) => {
            this.removeNonConformityReportsFromDataStore(
              data as ReducedNonConformityReport
            );
          },
        })
      );
  }

  async fetchAllManagers() {
    const usersData = (await this.amplifyApiService.request('get', {
      apiName: 'OnTraxAPI',
      path: '/quala-all-managers',
      options: {
        headers: this.defaultHeaders,
      },
    } as unknown as AmplifyRequestInput<'get'>)) as Array<User>;

    const users = usersData.map((json) => new User(json));
    this._managers.next(users);
    this.dataStore.allManagers = users;
  }

  isWashLineOfBusiness(array: string[]): boolean {
    if (!array) {
      return false;
    }
    return array.some((item) => item === 'WASH');
  }

  buildWarehouseData(warehouses: Warehouse[]) {
    const separator = ' - ';
    const formattedWarehouses: string[] = [];
    const warehousesMap:
      | { id: string; terminalName: string; terminalNumber: string }
      | {} = {};

    warehouses.forEach((item) => {
      if (
        item.active === 'false' &&
        this.isWashLineOfBusiness(item.linesOfBusiness)
      ) {
        return;
      }

      const terminalName = item.terminalName.replace(/^[^a-zA-Z]+/, '').trim();

      const formattedNameParts = [
        item.terminalNumberDisplay,
        item.terminalNameDisplay,
      ];
      const formattedNames: string[] = item.linesOfBusiness?.length
        ? item.linesOfBusiness.map((lineOfBusiness) =>
            [...formattedNameParts, lineOfBusiness, item.continent].join(
              separator
            )
          )
        : [formattedNameParts.join(separator)];

      formattedNames.forEach((formattedName) => {
        formattedWarehouses.push(formattedName);
        warehousesMap[formattedName] = {
          id: item.id,
          terminalName,
          terminalNumber: item.terminalNumber,
          linesOfBusiness: item.linesOfBusiness,
          terminalNumberDisplay: item.terminalNumberDisplay,
          terminalNameDisplay: item.terminalNameDisplay,
        };
      });
    });

    formattedWarehouses.sort();

    return {
      formattedWarehouses,
      warehousesMap,
    };
  }

  submitNCR(ncrId: string) {
    return this.amplifyApiService
      .request(
        'put',
        {
          apiName: 'NonConformityReportAPI',
          path: '/submit',
          options: {
            headers: this.defaultHeaders,
            body: {
              ncrId,
              userName: this.authService.user.username,
            },
          },
        } as unknown as AmplifyRequestInput<'put'>,
        true
      )
      .pipe(
        tap({
          next: (data: any) => {
            this.removeNonConformityReportsFromDataStore(
              data as ReducedNonConformityReport
            );
          },
        })
      );
  }
}
