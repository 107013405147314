import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AuthServiceCookieStorageKeys } from '../auth.service.enum';

import { AuthService } from '../auth.service';
import { CookieStorageService } from '../../services/cookie-storage.service';

enum PageState {
  Loading = 'loadingPage',
  Error = 'errorPage',
  NoAuthorization = 'noAuthorization',
}
@Component({
  selector: 'app-cognito-callback',
  standalone: true,
  imports: [],
  templateUrl: './cognito-callback.component.html',
  styleUrl: './cognito-callback.component.scss',
})
export class CognitoCallbackComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cookieStorageService: CookieStorageService
  ) {}

  isErrorCaught: PageState = PageState.Loading;
  retryUrl: string = '/';
  source: string = 'cognito-login';

  setPageState(state: PageState) {
    this.isErrorCaught = state;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      const authorizationCode = params['code'];
      const state = params['state'];

      if (state) {
        this.retryUrl = state;
      }

      try {
        if (!authorizationCode) {
          throw new Error('No Authorization Code');
        }

        const token = await this.exchangeCodeForToken(authorizationCode);
        const { id_token, access_token, refresh_token } = token;

        this.authService.setAmplifyV5PlusAuthCookies({
          idToken: id_token,
          accessToken: access_token,
          refreshToken: refresh_token,
        });

        await this.cookieStorageService.setFlag(
          AuthServiceCookieStorageKeys.IS_COGNITO_HOSTED_UI_LOGIN
        );

        if (state) {
          window.location.href = state;
        } else {
          window.location.href = this.retryUrl;
        }
      } catch (error) {
        await this.processError(error);
      }
    });
  }

  async exchangeCodeForToken(code: string): Promise<any> {
    const clientId = environment.amplifyConfig.Auth.Cognito.userPoolClientId;
    const redirectUri = environment.cognito.redirectUri;
    const tokenUrl = environment.cognito.tokenUrl;

    const params = new HttpParams()
      .set('code', code)
      .set('client_id', clientId)
      .set('redirect_uri', redirectUri)
      .set('grant_type', 'authorization_code');

    return await firstValueFrom(this.http.post(tokenUrl, params));
  }

  async processError(error: any) {
    if (error.message === 'No Authorization Code') {
      this.setPageState(PageState.NoAuthorization);
    } else {
      this.setPageState(PageState.Error);
    }

    const errorCaught = {
      source: this.source,
      caughtError: {
        errorMessage: error.message,
        errorStack: error.stack,
        error: error,
      },
      additionalData: { redirectUrl: this.retryUrl },
    };

    await this.authService.logError(errorCaught);
  }
}
