import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MenuOption } from '../menu/menu.model';
import { environment } from 'src/environments/environment';
import {
  AmplifyApiService,
  AmplifyRequestInput,
} from 'src/app/core/services/amplify-api.service';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private defaultHeaders: any;
  option: MenuOption;

  constructor(
    private authService: AuthService,
    private amplifyApiService: AmplifyApiService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };

    this.option = MenuOption.Reports;

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.defaultHeaders['x-ontrax-identity'] =
          `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`;
      }
    });
  }

  getDashboardTerminalReports(): Promise<any> {
    return this.amplifyApiService.request('get', {
      apiName: 'ReportsAPI',
      path: `/get-embed-url/${environment.reports.reportIds.terminalReports}`,
      options: { headers: this.defaultHeaders },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  getDashboardWashMetrics(): Promise<any> {
    return this.amplifyApiService.request('get', {
      apiName: 'ReportsAPI',
      path: `/get-embed-url/${environment.reports.reportIds.customerWashMetrics}`,
      options: { headers: this.defaultHeaders },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  getDashboardEhsWasteManagement(): Promise<any> {
    return this.amplifyApiService.request('get', {
      apiName: 'ReportsAPI',
      path: `/get-embed-url/${environment.reports.reportIds.ehsWasteManagement}`,
      options: { headers: this.defaultHeaders },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  getDashboardLeanDailyManagement(): Promise<any> {
    return this.amplifyApiService.request('get', {
      apiName: 'ReportsAPI',
      path: `/get-embed-url/${environment.reports.reportIds.leanDailyManagement}`,
      options: { headers: this.defaultHeaders },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  getDashboardById(dashboardId): Promise<any> {
    return this.amplifyApiService.request('get', {
      apiName: 'ReportsAPI',
      path: `/get-embed-url/${dashboardId}`,
      options: { headers: this.defaultHeaders },
    } as unknown as AmplifyRequestInput<'get'>);
  }
}
