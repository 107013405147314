import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  confirmSignIn,
  fetchAuthSession,
  FetchAuthSessionOptions,
  getCurrentUser,
  signIn,
  signOut,
} from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { KeycloakService } from 'keycloak-angular';
import { jwtDecode } from 'jwt-decode';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { environment } from 'src/environments/environment';

import { User } from '../model/user.model';
import { BehaviorSubject } from 'rxjs';
import { RoleName } from 'src/app/dashboard/menu/role-name.enum';
import { AuthServiceCookieStorageKeys } from './auth.service.enum';

import {
  AmplifyApiService,
  AmplifyRequestInput,
} from '../services/amplify-api.service';
import { CookieStorageService } from '../services/cookie-storage.service';
import { LocalStorageService } from '../localstorage.service';
import { ConnectivityService } from '../services/connectivity.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoginDepot: boolean;

  private defaultHeaders: any;
  private privateUser: User | null = null;
  user$: BehaviorSubject<User> = new BehaviorSubject(this.privateUser);

  constructor(
    private amplifyApiService: AmplifyApiService,
    private cookieStorageService: CookieStorageService,
    private localStorageService: LocalStorageService,
    private keycloak: KeycloakService,
    private gtmService: GoogleTagManagerService,
    private location: Location,
    private connectivityService: ConnectivityService,
    protected router: Router
  ) {
    this.defaultHeaders = { 'Content-Type': 'application/json' };
  }

  getKeycloakUsername() {
    const keycloakUsername = this.keycloak.getToken().then((token) => {
      const decoded: any = jwtDecode(token);
      const username = decoded.preferred_username;
      return username;
    });

    return keycloakUsername;
  }

  get user(): User {
    if (!this.privateUser) {
      this.privateUser = this.localStorageService.getUser();
    }
    return this.privateUser;
  }

  userHasGivenRole(roleName) {
    return this.user.roles.some((role) =>
      Object.keys(role).some((key) => {
        const isNameKey = key === 'name';
        const value = isNameKey ? role[key].split('.')[1] : key;

        return isNameKey ? value === roleName : value.includes(roleName);
      })
    );
  }

  hasDispatcherRole() {
    return (
      this.user.currentRole === 'Customer Dispatcher' ||
      this.user.currentRole === 'Customer All'
    );
  }

  getBusinessPartnerId() {
    return this.user.businessPartnerId;
  }

  getBusinessParterName() {
    return this.user.businessPartnerName;
  }

  hasQualaWorkerRole() {
    const role = this.user.currentRole || '';
    return (
      role.includes('Depot') ||
      role.includes('CSC') ||
      role.includes('Ticket') ||
      role.includes('-Manager') ||
      role.includes('Cleaner')
    );
  }

  isInternal() {
    const isExternalUser =
      this.user?.roles?.length > 0 ? this.hasDispatcherRole() : true;
    return this.user?.isInternal !== undefined
      ? this.user?.isInternal
      : !isExternalUser;
  }

  getUserType() {
    return this.isInternal() ? 'Internal' : 'External';
  }

  hasUserPlatformActions(action: string) {
    return (
      this.user.userPlatformActions && this.user.userPlatformActions[action]
    );
  }

  hasFullReportAccess() {
    const hasFullReportAccess =
      (this.hasUserPlatformActions('ncr') &&
        this.user.userPlatformActions.ncr.hasFullReportAccess) ||
      this.user.hasFullReportAccess ||
      false;
    return hasFullReportAccess;
  }

  hasAllReportActions() {
    const hasAllReportActions =
      (this.hasUserPlatformActions('ncr') &&
        this.user.userPlatformActions.ncr.hasAllReportActions) ||
      this.user.hasFullReportAccess ||
      false;
    return hasAllReportActions;
  }

  hasTerminalReportAccess() {
    const hasTerminalReportAccess =
      (this.hasUserPlatformActions('ncr') &&
        this.user.userPlatformActions.ncr.hasTerminalReportAccess) ||
      false;
    return hasTerminalReportAccess;
  }

  hasBusinessGroupAccessLevel() {
    const hasBusinessGroupReportAccess =
      (this.hasUserPlatformActions('ncr') &&
        this.user.userPlatformActions.ncr.hasBusinessGroupReportAccess) ||
      false;
    return hasBusinessGroupReportAccess;
  }

  hasRVPDepotRole() {
    return this.userHasGivenRole('RVP Depot');
  }

  hasManagerRole() {
    const role = this.user.currentRole || '';
    return role.includes('-Manager');
  }

  getUser() {
    if (!this.privateUser) {
      this.privateUser = this.localStorageService.getUser();
    }
    return this.privateUser;
  }

  returnMatchingRoles(roleName: RoleName) {
    const filteredRoles = this.user.roles.filter((role: object) => {
      const roles: string = Object.values(role)[0];
      return roles.includes(roleName);
    });
    const formattedRoles = filteredRoles
      .map((item) => {
        if (item?.name === 'NCR.RVP Depot') {
          return item.terminals.map(
            (terminal) => `${terminal?.terminalNumber} RVP Depot`
          );
        }
        return Object.keys(item)[0];
      })
      .flat();

    return formattedRoles;
  }

  async getAuthSessionOrCachedTokens(
    fetchAuthSessionOptions?: FetchAuthSessionOptions
  ): ReturnType<typeof fetchAuthSession> {
    // If the user is offline, return only cached tokens (if they exist) to
    // avoid network calls.
    if (!this.connectivityService.isOnline) {
      const tokens = await cognitoUserPoolsTokenProvider.tokenOrchestrator
        .getTokenStore()
        .loadTokens();
      return { tokens };
    }

    return await fetchAuthSession(fetchAuthSessionOptions);
  }

  async isAuthorized() {
    try {
      return !!(await this.getAuthSessionOrCachedTokens()).identityId;
    } catch (error) {
      return false;
    }
  }

  async isKeycloakUser(): Promise<boolean> {
    const keycloakInstance = this.keycloak.getKeycloakInstance();

    if (!!keycloakInstance?.token) {
      return true;
    }

    const session = await this.getAuthSessionOrCachedTokens();

    return session?.tokens?.idToken
      ? session?.tokens?.idToken.payload.iss.includes(
          environment.keycloak.issuer
        )
      : false;
  }

  async unauthorizeUser() {
    this.clearUserData();
    await signOut();
  }

  async clearUserData() {
    this.localStorageService.emptyLocalStorage();
    this.privateUser = null;
  }

  async refreshCredentials() {
    const isAuthorized = await this.isAuthorized();

    if (!isAuthorized) {
      await this.authorize();
    }
  }

  async refreshUserInformation() {
    let userInformationExpireTime =
      this.localStorageService.getUserInformationExpireTime();

    const isInformationExpired = Date.now() > userInformationExpireTime;

    if (userInformationExpireTime === null || isInformationExpired) {
      const expirationMinutes = 5;
      userInformationExpireTime = new Date().setMinutes(
        new Date().getMinutes() + expirationMinutes
      );
    }

    if (isInformationExpired) {
      this.localStorageService.setUserInformationExpireTime(
        userInformationExpireTime
      );
      await this.getProfile();
    }
  }

  async authorize() {
    const {
      idToken,
      token: accessToken,
      refreshToken,
    } = this.keycloak.getKeycloakInstance();

    if (idToken && accessToken && refreshToken) {
      await this.setAmplifyV5PlusAuthCookies({
        accessToken,
        idToken,
        refreshToken,
      });
    }
  }

  async isOnTraxLoggedUserSameAsSSOLoggedUser(): Promise<boolean> {
    const isLoggedId = await this.isLoggedIn();

    if (isLoggedId && this.user) {
      const token = await this.keycloak.getToken();
      const decoded: any = jwtDecode(token);
      const username = decoded.preferred_username;
      return this.user.username === username;
    }

    return false;
  }

  async isUserPoolLogged() {
    const user = await getCurrentUser().catch(() => undefined);
    return user?.signInDetails?.authFlowType === 'USER_AUTH';
  }

  async needToCleanUserPoolCache() {
    const isUserPoolLogged =
      this.localStorageService.getAuthType() === 'USER_POOL';
    const isLoggedIn = await this.isUserPoolLogged();
    return !isLoggedIn && isUserPoolLogged;
  }

  async setAmplifyV5PlusAuthCookies({ idToken, accessToken, refreshToken }) {
    const clientId = environment.amplifyConfig.Auth.Cognito.userPoolClientId;
    const keyPrefix = `CognitoIdentityServiceProvider.${clientId}`;

    const decodedIdToken = jwtDecode(idToken);
    const decodedAccessToken = jwtDecode(accessToken);

    const username =
      decodedIdToken['cognito:username'] ||
      decodedIdToken['preferred_username'];

    const clockDrift = decodedAccessToken.iat * 1000 - new Date().getTime();

    const idTokenKey = `${keyPrefix}.${username}.idToken`;
    const accessTokenKey = `${keyPrefix}.${username}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.${username}.refreshToken`;
    const clockDriftKey = `${keyPrefix}.${username}.clockDrift`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;

    await Promise.all([
      this.cookieStorageService.setItem(idTokenKey, idToken),
      this.cookieStorageService.setItem(accessTokenKey, accessToken),
      this.cookieStorageService.setItem(refreshTokenKey, refreshToken),
      this.cookieStorageService.setItem(clockDriftKey, `${clockDrift}`),
      this.cookieStorageService.setItem(lastUserKey, username),
    ]);
  }

  // obsolete after new login page
  async signIn(userInfo: { username: string; password: string }) {
    let user;

    user = await signIn(userInfo);

    if (
      user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
    ) {
      return { newPasswordRequired: true };
    }

    await this.getProfile();
    return user;
  }

  async completeNewPassword(newPassword: string) {
    try {
      this.localStorageService.emptyLocalStorage();

      await confirmSignIn({ challengeResponse: newPassword });

      this.localStorageService.setAuthType('USER_POOL');

      await this.getProfile();

      return {
        code: 200,
      };
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        return {
          code: 401,
        };
      }
    }
  }

  async isLoggedIn(): Promise<boolean> {
    const user = await getCurrentUser().catch(() => undefined);
    return !!user?.userId;
  }

  async forceDepotLocalStorageClean() {
    const needToCleanCache = await this.needToCleanUserPoolCache();
    if (needToCleanCache && this.location.path() === '') {
      this.clearUserData();
    }
  }

  async login(
    { redirectUri },
    userInfo?: { username: string; password: string }
  ) {
    // this if is obsolete after new login page
    if (!redirectUri && userInfo) {
      this.localStorageService.setAuthType('USER_POOL');
      const response = await this.signIn(userInfo);
      return response;
    }
    await this.redirect({ redirectUri });
  }

  async redirect({ redirectUri }) {
    if (this.localStorageService.getAuthType() === 'USER_POOL') {
      window.location.assign('/login-depot');
      return;
    }
    await this.keycloak.login({ redirectUri });
  }

  async logout(
    authType?: string,
    options?: {
      isIframeLogout?: boolean;
      origin?: string;
    }
  ) {
    await signOut();

    if (options && options.isIframeLogout && options.origin) {
      this.sendParentLogoutMessage(options.origin);
    }

    // Logout from Cognito Hosted UI so the user is prompted to select their
    // account again when logging back in.
    if (
      await this.cookieStorageService.isFlagTrue(
        AuthServiceCookieStorageKeys.IS_COGNITO_HOSTED_UI_LOGIN
      )
    ) {
      await this.cookieStorageService.removeItem(
        AuthServiceCookieStorageKeys.IS_COGNITO_HOSTED_UI_LOGIN
      );
      return await this.cognitoHostedUiLogout();
    }

    // Only for e2e testing purposes
    if (authType === 'USER_POOL') {
      if (environment.redirectToNewLoginPage) {
        window.location.assign('/login-depot-tests');
      } else {
        window.location.assign('/login-depot');
      }
      return;
    }

    await this.keycloak.logout(window.location.origin);
  }

  /**
   * It is necessary to send logout message for cases where the OnTrax is an iFrame, like in reports portal.
   * @param origin The origin where the iframe is being used
   */
  private sendParentLogoutMessage(origin) {
    window.parent.postMessage({ status: 'logged_out' }, origin);
  }

  private async cognitoHostedUiLogout() {
    const cognitoLogoutUrl = new URL(`${environment.cognito.domainUrl}/logout`);

    cognitoLogoutUrl.searchParams.set(
      'client_id',
      environment.amplifyConfig.Auth.Cognito.userPoolClientId
    );

    cognitoLogoutUrl.searchParams.set(
      'logout_uri',
      environment.cognito.logoutUri
    );

    await this.cookieStorageService.setFlag(
      AuthServiceCookieStorageKeys.IS_REDIRECTING_TO_HOSTED_UI_LOGOUT
    );

    window.location.href = cognitoLogoutUrl.toString();
  }

  async getProfile() {
    try {
      const response = await this.amplifyApiService.request('get', {
        apiName: 'AuthAPI',
        path: '/fetch-user-profile',
        options: {
          headers: this.defaultHeaders,
        },
      } as unknown as AmplifyRequestInput<'get'>);

      const user = new User(response);
      this.setUser(user);
      this.setUserPropertiesOnDataLayer();

      return user;
    } catch (error) {
      this.router.navigate(['/login-error']);
      throw new Error('Error when trying to get users profile');
    }
  }

  async changeRole(toRole: string) {
    const identity = `${this.user.username};${this.user.currentRoleAcronym}`;
    const profile = await this.changeUserRole(toRole, identity);
    const user = new User(profile);
    this.setUser(user);
    this.user$.next(user);
    this.setUserPropertiesOnDataLayer();

    return user;
  }

  private setUser(user: User) {
    this.localStorageService.setUser(user);
    this.privateUser = user;
  }

  private async changeUserRole(toRole: string, identity: string) {
    return await this.amplifyApiService.request('post', {
      apiName: 'AuthAPI',
      path: '/change-role',
      options: {
        headers: {
          ...this.defaultHeaders,
          'x-ontrax-identity': identity,
        },
        body: { targetRole: toRole },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  private setUserPropertiesOnDataLayer() {
    const userProperties = {
      event: 'setUserProperties',
      username: this.user.username,
      role: this.user.currentRoleAcronym,
      ...(this.user.currentTerminal && {
        terminal: Number(this.user.currentTerminal.number),
      }),
      ...(this.user.businessPartnerName && {
        businessPartner: this.user.businessPartnerName,
      }),
    };

    this.gtmService.pushTag(userProperties);
  }

  getUserSessionExpirationTime() {
    return this.localStorageService.getUserInformationExpireTime();
  }

  async logUnauthorizedPageAccess(data) {
    const identity = `${this.user.username};${this.user.currentRoleAcronym}`;

    return await this.amplifyApiService.request('post', {
      apiName: 'AuthAPI',
      path: '/log-unauthorized-user',
      options: {
        headers: {
          ...this.defaultHeaders,
          'x-ontrax-identity': identity,
        },
        body: { data },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  async logError(error) {
    error.userAgent = navigator.userAgent;
    error.currentUrl = window.location.href;
    error.currentUser = this.user;
    error.additionalData = {
      clientTimestamp: new Date(),
      clientTimezone: new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1],
    };

    return await this.amplifyApiService.request('post', {
      apiName: 'AuthAPI',
      path: '/log-handler',
      options: {
        headers: this.defaultHeaders,
        body: error,
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  async getServerCurrentTimeUTC() {
    return await this.amplifyApiService.request('get', {
      apiName: 'OnTraxAPI',
      path: '/get-current-time-utc',
      options: {
        headers: this.defaultHeaders,
      },
    } as unknown as AmplifyRequestInput<'get'>);
  }
}
