import { Injectable } from '@angular/core';
import {
  APIService,
  GetBusinessPartnerNotificationPreferencesByIdQuery,
  GetUserNotificationPreferencesByIdQuery,
} from 'src/app/core/API.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MenuOption } from '../menu/menu.model';
import {
  BusinessPartnerNotificationPreferences,
  Dispatcher,
  GenericUser,
  QualaUser,
  ShareUserNotification,
  UserNotificationPreferences,
} from './notification-preferences.model';
import {
  AmplifyApiService,
  AmplifyRequestInput,
} from 'src/app/core/services/amplify-api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationPreferencesService {
  private defaultHeaders: any;
  option: MenuOption;

  constructor(
    private authService: AuthService,
    private amplifyApiService: AmplifyApiService,
    private appSyncService: APIService
  ) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-ontrax-identity': `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`,
    };

    this.option = MenuOption.ShiftLogs;

    this.authService.user$.subscribe((user) => {
      if (user) {
        this.defaultHeaders['x-ontrax-identity'] =
          `${this.authService.user.username};${this.authService.user.currentRoleAcronym}`;
      }
    });
  }

  sendBusinessPartnerNotificationPreferences(
    businessPartnerNotificationPreferences: BusinessPartnerNotificationPreferences
  ) {
    return this.amplifyApiService.request('post', {
      apiName: 'NotificationPreferencesAPI',
      path: '/business-partner',
      options: {
        headers: this.defaultHeaders,
        body: { ...businessPartnerNotificationPreferences },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  sendUserPartnerNotificationPreferences(
    userNotificationPreferences: UserNotificationPreferences
  ) {
    return this.amplifyApiService.request('post', {
      apiName: 'NotificationPreferencesAPI',
      path: '/users',
      options: {
        headers: this.defaultHeaders,
        body: {
          aduserId: userNotificationPreferences.aduserId,
          forwardUsersIds: userNotificationPreferences.forwardUsersIds,
        },
      },
    } as unknown as AmplifyRequestInput<'post'>);
  }

  async getLoggedBusinessPartnerNotificationPreferences() {
    return await this.getBusinessPartnerNotificationPreferences(
      this.authService.getBusinessPartnerId()
    );
  }

  async getBusinessPartnerNotificationPreferences(
    businessPartnerId: string
  ): Promise<GetBusinessPartnerNotificationPreferencesByIdQuery> {
    return await this.appSyncService.GetBusinessPartnerNotificationPreferencesById(
      businessPartnerId
    );
  }

  async getUserNotificationPreferences(
    adUserId: string
  ): Promise<GetUserNotificationPreferencesByIdQuery> {
    return await this.appSyncService.GetUserNotificationPreferencesById(
      adUserId
    );
  }

  getDispatchers(businessPartnerId: string): Promise<Dispatcher[]> {
    return this.amplifyApiService.request('get', {
      apiName: 'OnTraxAPI',
      path: '/get-dispatchers',
      options: {
        headers: this.defaultHeaders,
        queryParams: {
          businessPartnerId,
        },
      },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  getQualaUsers(terminalNumber: string): Promise<QualaUser[]> {
    return this.amplifyApiService.request('get', {
      apiName: 'OnTraxAPI',
      path: '/quala-users',
      options: {
        headers: this.defaultHeaders,
        queryParams: {
          terminalNumber,
        },
      },
    } as unknown as AmplifyRequestInput<'get'>);
  }

  getAllUsersToForwardNotifications(): Promise<GenericUser[]> {
    if (this.authService.hasQualaWorkerRole()) {
      return this.getQualaUsers(this.authService.user.currentTerminal.number);
    } else {
      return this.getDispatchers(this.authService.user.businessPartnerId);
    }
  }

  async getUserNotificationPreferencesForSelect(): Promise<ShareUserNotification> {
    const response = await Promise.all([
      this.getUserNotificationPreferences(this.authService.user.id),
      this.getAllUsersToForwardNotifications(),
    ]);

    const userPreferences = response[0];
    const allUsersToForward = response[1];

    const forwardUsersIds =
      (userPreferences && userPreferences.forwardUsersIds) || [];
    const sharingWith = allUsersToForward.filter((user) =>
      forwardUsersIds.includes(user.id)
    );

    const allUserExceptLoggedUser =
      allUsersToForward.filter(
        (user) => user.id !== this.authService.user.id
      ) || [];

    return {
      sharingWith,
      allUsers: allUserExceptLoggedUser,
    };
  }
}
