import { AuthServiceCookieStorageKeys } from '../auth/auth.service.enum';
import { CookieStorageService } from '../services/cookie-storage.service';

export function initializeCookieStorage(
  cookieStorageService: CookieStorageService
): () => Promise<void> {
  return async () => {
    cleanupTemporaryCookies(cookieStorageService);
  };
}

async function cleanupTemporaryCookies(
  cookieStorageService: CookieStorageService
) {
  await cookieStorageService.removeItem(
    AuthServiceCookieStorageKeys.IS_REDIRECTING_TO_HOSTED_UI_LOGOUT
  );
}
