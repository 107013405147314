import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MasterCustomer } from 'src/app/dashboard/non-conformity-report/non-conformity-report.model';
import { AmplifyApiService, AmplifyRequestInput } from './amplify-api.service';

interface DataStore {
  masterAccounts: MasterCustomer[];
  isLoadingBPs: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MasterAccountService {
  private defaultHeaders: any;

  public dataStore: DataStore = {
    masterAccounts: [],
    isLoadingBPs: false,
  };

  constructor(private amplifyApiService: AmplifyApiService) {
    this.defaultHeaders = {
      'Content-Type': 'application/json',
    };
  }

  private _masterAccounts = new BehaviorSubject<MasterCustomer[]>(
    this.dataStore.masterAccounts
  );
  public masterAccounts$: Observable<MasterCustomer[]> =
    this._masterAccounts.asObservable();

  private isLoadingBPs = new BehaviorSubject<boolean>(false);
  public isLoadingBPs$ = this.isLoadingBPs.asObservable();

  async getMasterCustomer() {
    this.isLoadingBPs.next(true);

    return this.amplifyApiService
      .request('get', {
        apiName: 'NonConformityReportAPI',
        path: '/master-customer',
        options: {
          headers: this.defaultHeaders,
        },
      } as unknown as AmplifyRequestInput<'get'>)
      .then((masterCustomers) => {
        const customers = (masterCustomers as Array<MasterCustomer>).map(
          (json) => new MasterCustomer(json)
        );

        this._masterAccounts.next(customers);
        this.dataStore.masterAccounts = customers;
      })
      .finally(() => {
        this.isLoadingBPs.next(false);
      });
  }

  async externalGetMasterCustomer() {
    this.isLoadingBPs.next(true);

    return this.amplifyApiService
      .request('get', {
        apiName: 'NonConformityReportAPI',
        path: '/master-customer',
        options: {
          headers: this.defaultHeaders,
        },
      } as unknown as AmplifyRequestInput<'get'>)
      .then((masterCustomers) => {
        const customers = (masterCustomers as Array<MasterCustomer>).map(
          (json) => new MasterCustomer(json)
        );

        this._masterAccounts.next(customers);
        this.dataStore.masterAccounts = customers;
      })
      .finally(() => {
        this.isLoadingBPs.next(false);
      });
  }
}
