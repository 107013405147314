import { Injectable } from '@angular/core';
import { get, post, put, del } from '@aws-amplify/api';
import {
  GetInput,
  PostInput,
  PutInput,
  DeleteInput,
  GetOperation,
  PostOperation,
  PutOperation,
  DeleteOperation,
  RestApiResponse,
} from '@aws-amplify/api-rest/src/types';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export type AmplifyHttpMethod = 'get' | 'post' | 'put' | 'delete';

export type AmplifyRequestInput<T extends AmplifyHttpMethod> = T extends 'get'
  ? GetInput
  : T extends 'post'
    ? PostInput
    : T extends 'put'
      ? PutInput
      : DeleteInput;

@Injectable({
  providedIn: 'root',
})
export class AmplifyApiService {
  constructor() {}

  request<T extends AmplifyHttpMethod>(
    method: T,
    input: AmplifyRequestInput<T>,
    asObservable: true
  ): Observable<any>;
  request<T extends AmplifyHttpMethod>(
    method: T,
    input: AmplifyRequestInput<T>,
    asObservable?: false
  ): Promise<any>;
  request<T extends AmplifyHttpMethod>(
    method: T,
    input: AmplifyRequestInput<T>,
    asObservable: boolean = false
  ): Observable<any> | Promise<any> {
    const operation = this.selectMethod(method)(input);

    if (asObservable) {
      return from(operation.response).pipe(
        mergeMap((response: RestApiResponse) => {
          if (this.hasBody(response)) {
            return this.parseResponseText(response);
          }
          return null;
        }),
        catchError((error) => {
          const transformedError = this.transformError(error);
          return throwError(() => transformedError);
        })
      );
    }

    return operation.response
      .then((response) => {
        if (this.hasBody(response)) {
          return this.parseResponseText(response);
        }
        return null;
      })
      .catch((error) => {
        throw this.transformError(error);
      });
  }

  private transformError(error: any): any {
    if (error.response && typeof error.response.body === 'string') {
      try {
        const parsedBody = JSON.parse(error.response.body);
        return {
          ...error,
          response: {
            ...error.response,
            parsedBody,
          },
        };
      } catch (parseError) {
        return {
          ...error,
          response: {
            ...error.response,
            parsedBody: null,
            parsedBodyError: parseError,
          },
        };
      }
    }

    return error;
  }

  private parseResponseText(response: RestApiResponse): Promise<any> {
    return response.body.text().then((body) => {
      if (!body) {
        return null;
      }

      return JSON.parse(body);
    });
  }

  private hasBody(
    response: RestApiResponse | Omit<RestApiResponse, 'body'>
  ): response is RestApiResponse {
    return 'body' in response;
  }

  private selectMethod(
    method: 'get' | 'post' | 'put' | 'delete'
  ): (
    input: GetInput | PostInput | PutInput | DeleteInput
  ) => GetOperation | PostOperation | PutOperation | DeleteOperation {
    switch (method) {
      case 'get':
        return get as (input: GetInput) => GetOperation;
      case 'post':
        return post as (input: PostInput) => PostOperation;
      case 'put':
        return put as (input: PutInput) => PutOperation;
      case 'delete':
        return del as (input: DeleteInput) => DeleteOperation;
      default:
        throw new Error(`Unsupported method: ${method}`);
    }
  }
}
