import { Injectable } from '@angular/core';
import { CookieStorage } from 'aws-amplify/utils';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  cookieStorage: CookieStorage;

  constructor() {
    this.cookieStorage = new CookieStorage(
      environment.amplifyCustomConfig.cookieStorageData
    );
  }

  async getItem(key): Promise<string | null> {
    return await this.cookieStorage.getItem(key);
  }

  async setItem(key, value): Promise<void> {
    await this.cookieStorage.setItem(key, value);
  }

  async removeItem(key): Promise<void> {
    await this.cookieStorage.removeItem(key);
  }

  async clear(): Promise<void> {
    await this.cookieStorage.clear();
  }

  async isFlagTrue(flagName: string): Promise<boolean> {
    const value = await this.getItem(flagName);
    return value === 'true';
  }

  async isFlagFalse(flagName: string): Promise<boolean> {
    const value = await this.getItem(flagName);
    return value === 'false';
  }

  async flagExists(flagName: string): Promise<boolean> {
    const value = await this.getItem(flagName);
    return value !== null && value !== undefined;
  }

  async setFlag(flagName: string, value: boolean = true): Promise<void> {
    await this.setItem(flagName, value.toString());
  }

  async getJson(key: string): Promise<any | null> {
    const item = await this.getItem(key);

    return item ? JSON.parse(item) : null;
  }

  async setJson(key: string, json: any): Promise<void> {
    const valueToStore = json === undefined ? null : json;

    await this.setItem(key, JSON.stringify(valueToStore));
  }
}
